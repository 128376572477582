import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentConfiguration} from '../shared/EnvironmentConfiguration';
import {Observable} from 'rxjs';

interface PictoOptions {
  foreground: string;
  background: string;
  border: string | boolean;
  pointer: string | boolean;
}

@Injectable()
export class SvgService {

  public listInforisque = [
    {
      alias: 'inondation',
      name_fr: 'Risque inondation',
      name_en: 'Flood'
    },
    {
      alias: 'ruissellement',
      name_fr: 'Ruissellement',
      name_en: 'runoff'
    },
    {
      alias: 'orages',
      name_fr: 'Orages',
      name_en: 'Thunderstorms'
    },
    {
      alias: 'grele',
      name_fr: 'Risque grêle',
      name_en: 'Hail'
    },
    {
      alias: 'coup_vent',
      name_fr: 'Risque coup de vent',
      name_en: 'Wind blast'
    },
    {
      alias: 'tempete',
      name_fr: 'Tempête',
      name_en: 'Storm'
    },
    {
      alias: 'neige',
      name_fr: 'Risque neige',
      name_en: 'Snow'
    },
    {
      alias: 'grand_froid',
      name_fr: 'Grand froid',
      name_en: 'Extreme cold'
    },
    {
      alias: 'canicule',
      name_fr: 'Canicule',
      name_en: 'Heat wave'
    },
    {
      alias: 'seisme',
      name_fr: 'Séisme',
      name_en: 'Earthquake'
    },
    {
      alias: 'qualite_eau',
      name_fr: 'Baignade interdite',
      name_en: 'Prohibited bathing'
    },
    {
      alias: 'point_information',
      name_fr: 'Information',
      name_en: 'Information'
    },
    {
      alias: 'volcan',
      name_fr: 'Risque volcanique',
      name_en: 'Volcano'
    },
    {
      alias: 'attention',
      name_fr: 'Attention',
      name_en: 'Warning'
    },
    {
      alias: 'tsunami',
      name_fr: 'Risque de tsunami',
      name_en: 'Tsunami'
    },
    {
      alias: 'cyclone',
      name_fr: 'Risque cyclone',
      name_en: 'Cyclone'
    },
    {
      alias: 'pollution',
      name_fr: 'Risque pollution',
      name_en: 'Pollution risk'
    },
    {
      alias: 'submersion',
      name_fr: 'Risque submersion',
      name_en: 'Storm surge'
    },
    {
      alias: 'no_risque',
      name_fr: 'Pas de risque',
      name_en: 'No risk'
    }
  ];
  public listLabelBody = [
    {alias: 'label-a'},
    {alias: 'label-b'},
    {alias: 'label-c'},
    {alias: 'label-d'},
    {alias: 'label-e'},
    {alias: 'label-f'},
    {alias: 'label-g'},
    {alias: 'label-h'},
    {alias: 'label-i'},
    {alias: 'label-j'},
    {alias: 'label-k'},
    {alias: 'label-l'},
    {alias: 'label-m'},
    {alias: 'label-n'},
    {alias: 'label-o'},
    {alias: 'label-p'},
  ];
  public listPlanPicto = [
    {alias: 'aeroport'},
    {alias: 'agricole'},
    {alias: 'antenne'},
    {alias: 'atelier_municipaux'},
    {alias: 'attention'},
    {alias: 'autres'},
    {alias: 'barrage_retenue'},
    {alias: 'bati_administratif'},
    {alias: 'batiment_refuge'},
    {alias: 'busage'},
    {alias: 'camping'},
    {alias: 'caserne_pompier'},
    {alias: 'centre_accueil'},
    {alias: 'commerce'},
    {alias: 'commercial'},
    {alias: 'decheterie'},
    {alias: 'deneigement'},
    {alias: 'depose_eleve'},
    {alias: 'education'},
    {alias: 'elevage'},
    {alias: 'entreprise'},
    {alias: 'equipement_sportif'},
    {alias: 'fermeture_route'},
    {alias: 'gare'},
    {alias: 'gr_habitation'},
    {alias: 'gr_habitation_eau'},
    {alias: 'grue'},
    {alias: 'habitat'},
    {alias: 'habitation'},
    {alias: 'habitation_eau'},
    {alias: 'hebergement'},
    {alias: 'heliport'},
    {alias: 'icpe'},
    {alias: 'industriel'},
    {alias: 'itineraire_evacuation'},
    {alias: 'loisirs'},
    {alias: 'medical'},
    {alias: 'parc'},
    {alias: 'parking'},
    {alias: 'parking_accueil'},
    {alias: 'parking_inondable'},
    {alias: 'parking_souterrain'},
    {alias: 'pc_crise'},
    {alias: 'personne_vulnerable'},
    {alias: 'petite_enfance'},
    {alias: 'plage'},
    {alias: 'point_regroupement'},
    {alias: 'point_surveillance'},
    {alias: 'police'},
    {alias: 'police_gendarmerie'},
    {alias: 'pompier'},
    {alias: 'pont'},
    {alias: 'port'},
    {alias: 'seveso'},
    {alias: 'sirene'},
    {alias: 'station_epuration'},
    {alias: 'surveillance'},
    {alias: 'transformateur'},
    {alias: 'transports'},
    {alias: 'tremie'},
    {alias: 'zone_activite'},
    {alias: 'zone_refuge'},
  ];

  public urlPictos =  EnvironmentConfiguration.urls.picto;

  constructor(
    public httpClient: HttpClient,
  ) {}

  getAllInforisques(options: PictoOptions): Promise<any> {
    return Promise.all(this.listInforisque.map(inforisque => this.getInforisque(inforisque.alias, options)));
  }

  getInforisque(alias, options: PictoOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get( `${this.urlPictos}picto/inforisque/${alias}.svg?cursor=false&border=`, {
        responseType: 'text'
      }).subscribe(svgTextElement => {
        resolve(this.handleInforisqueOptions(alias, svgTextElement, options));
      }, (err) => {
        reject(err);
      });
    });
  }

  handleInforisqueOptions(alias, svgTextElement, options: PictoOptions): any {
    const trad = this.listInforisque.find(item => item.alias === alias);
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgTextElement, 'image/svg+xml');
    const svgElement: any = doc.firstChild;
    const foreground = Array.from(doc.getElementsByClassName('foreground'));
    const background = doc.getElementById('background');


    if (foreground.length && options.foreground) {
      foreground.forEach(item => {
        item.setAttribute('fill', options.foreground);
      });
    }

    if (background && options.background) {
      background.setAttribute('fill', options.background);
    }
    console.log('{SvgService} -> handleInforisqueOptions()', doc, background)

    return {
      name_fr: trad?.name_fr || alias,
      alias,
      svgElement
    };
  }

  getAllLabelBody(): Promise<any> {
    return Promise.all(this.listLabelBody.map(label => this.getLabelBody(label.alias)));
  }

  getLabelBody(alias): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get( `./assets/svg/label/${alias}.svg`, {
        responseType: 'text'
      }).subscribe(svgTextElement => {
        resolve(this.handleSvg(alias, svgTextElement));
      }, (err) => {
        reject(err);
      });
    });
  }

  getAllPlanPicto(): Promise<any> {
    return Promise.all(this.listPlanPicto.map(label => this.getPlanPictoSvg(label.alias)));
  }

  getPlanPictoSvg(alias): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get( `${this.urlPictos}picto-svg/plan/${alias}.svg`, {
        responseType: 'text'
      }).subscribe(svgTextElement => {
        resolve(this.handleSvg(alias, svgTextElement));
      }, (err) => {
        reject(err);
      });
    });
  }

  handleSvg(alias, svgTextElement): any {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgTextElement, 'image/svg+xml');

    return {
      alias,
      svgElement: doc.firstChild
    };
  }

  getSvgContent(svgElement): any {
    return Array.from(svgElement.children).filter((a: any) => a.nodeName === 'g')[0];
  }

  getBlob(src): any {
      return this.httpClient.get(src, {
        responseType: 'blob'
      });
  }

  getSvgFromImgUri(src): any {
    return new Promise((resolve, reject) => {
      this.httpClient.get( src, {
        responseType: 'blob'
      }).subscribe(blob => {

        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          const img = new Image();


          img.src = '' + reader.result;
          document.body.appendChild(img);

          img.onload = (i) => {
            console.log('[SvgService] -> onload() -> ', i, img
            )

            document.body.removeChild(img);
            resolve({
              width: img.naturalWidth,
              height: img.naturalHeight,
              base64: reader.result,
              blob
            });
          };
        };




      }, (err) => {
        reject(err);
      });
    });
  }

  private blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
}
