<header class="main-header d-flex justify-content-between align-items-center">
  <div style="width:20%">
    <a  routerLink="/plan/{{idMapA0}}" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b>
    Enjeux et actions de {{plan?.title}}
  </b>

  <div style="width:20%" class="d-flex justify-content-end">
    <button class="pm-btn pm-btn-primary me-2" (click)="openModal()">Modifier la légende</button>

    <app-steps [type]="'legend_dynamic'"></app-steps>
  </div>
</header>
<div class="iframe">
  <iframe [src]='urlIframe'></iframe>
</div>
