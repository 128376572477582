<h2 mat-dialog-title *ngIf="!!title" [innerHTML]="title"></h2>
<mat-dialog-content [innerHTML]="content"></mat-dialog-content>
<div mat-dialog-actions class="justify-content-center align-items-center">

  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

  <button class="{{ btnCancelClass }} m-1" mat-dialog-close cdkFocusInitial>{{ btnCancel }}</button>
  <button class="{{ btnActionClass }} m-1" (click)="resetDefaultUrl()">{{ btnResetPdf }}</button>
  <button class="{{ btnActionClass }} m-1" (click)="fileUpload.click()">{{ btnNewPdf }}</button>
</div>
