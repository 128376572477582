import { Component, OnInit } from '@angular/core';
import {EnvironmentConfiguration} from '../../../shared/EnvironmentConfiguration';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MapperService} from '../../../services/mapper.service';
import {UploadDialogComponent} from '../upload-dialog/upload.dialog.component';
import {PlanAction} from '../../../shared/entities/A0.entity';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DownloadService} from '../../../services/download.service';
import {zip} from 'rxjs';


@Component({
  selector: 'app-cartouche',
  templateUrl: './cartouche.component.html',
  styleUrls: ['./cartouche.component.scss']
})
export class CartoucheComponent implements OnInit {

  public urlIframe: SafeResourceUrl;
  public idMapA0: string;
  public plan: any;
  public listLogo = [];
  public isCustom = false;

  constructor(
    public mapperService: MapperService,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public downloadService: DownloadService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.idMapA0 = this.route.snapshot.paramMap.get('id');
    this.getPlan(this.idMapA0);
  }

  public getPlan(id): void {
    zip(
      this.mapperService.getPlan(id),
      this.mapperService.getCartoucheLogos(id)
    ).subscribe(([plan, listLogo]) => {
        this.plan = plan;
        const url = this.plan.url_entete;

        this.isCustomPdf(this.plan);

        this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;

        this.listLogo = listLogo;
      });
  }

  public onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.downloadService.uploadCartoucheLogo(file, this.plan.id).subscribe(res => {
      if (res?.state === 'DONE') {
        this.getPlan(this.plan.id);
      }
    }, () => {
      this.snackbar.open('Une erreur est survenue pendant l\'upload', 'ok', {
        duration: 3000
      });
    });
  }

  public deleteCartoucheLogo(idLogo): void {
    this.mapperService.deleteCartoucheLogo(idLogo).subscribe(() => {
      this.getPlan(this.plan.id);
    });
  }

  public isCustomPdf(plan): void {
    this.mapperService.isCustomPdf(plan.id, PlanAction.ENTETE, plan.url_entete).subscribe(isCustom => {
      this.isCustom = isCustom;
    });
  }

  resetPdf(): void {
    this.mapperService.resetDefaultUrlAction(this.plan.id, PlanAction.ENTETE).subscribe((res) => {
      this.snackbar.open('le pdf par défaut a bien été restauré', 'ok', {
        duration: 3000
      });

      this.getPlan(this.plan.id);
    }, (err) => {
      this.snackbar.open('Une erreur est survenue', 'ok', {
        duration: 3000
      });
    });
  }

  public openModal(): void {
    this.dialog.open(UploadDialogComponent, {
      data: {
        title: '',
        content: `
            <p class="pb-0 mb-0">
               Voulez vous modifier la cartouche ?
               <br>
               Le document doit être un pdf de 270mm de haut pour 180mm de large.
            </p>
        `,
        plan: this.plan,
        planAction: PlanAction.ENTETE
      },
      panelClass: 'dialog-warning',
      width: '600px'
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.getPlan(this.idMapA0);
      }
    });
  }
}
