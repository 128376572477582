import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MapperService} from '../../../services/mapper.service';

@Component({
  selector: 'app-title.dialog',
  templateUrl: './title.dialog.component.html',
})
export class TitleDialogComponent {

  plan;
  title;
  isLoading = false;

  constructor(
    public mapperService: MapperService,
    public dialogRef: MatDialogRef<TitleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      plan
    },
  ) {
    this.plan = data.plan;
    this.title = data.plan.title;
  }

  save(): void {
    this.isLoading = true;
    const plan = { id: this.plan.id, title: this.title };

    this.mapperService.updatePlan(plan).subscribe((res) => {
      this.isLoading = false;

      if (res.updated) {
        this.dialogRef.close(res.updated);
      }
    }, () => {
      this.isLoading = false;
    });
  }
}
