import {Component} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  template: `
    <div class="d-flex justify-content-center align-items-center h-100 p-3" >
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  `,
})
export class JwtLoginComponent {
  constructor(
    public authService: AuthService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.authService.jwtLogin(params['token']).subscribe(() => {
        this.router.navigate(['/dashboard']);
      }, () => {
        this.router.navigate(['/unauthorized']);
      });
    });
  }
}

