import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '../services/loader.service';
import {Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  template: `
    <div class="d-flex justify-content-center align-items-center h-100 p-3" >
      <div class="alert alert-danger" role="alert" [style.width.px]="400">
        <h4 class="alert-heading fw-bold">Non autorisé</h4>
        <p>Vous n’êtes pas connectés. Veuillez accèder au predict mapper en passant par le wiki</p>
      </div>
    </div>
  `,
})
export class UnauthorizedComponent {
  constructor() {}
}

