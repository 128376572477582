import {Injectable} from '@angular/core';
import {ModuleInterface} from '../shared/ModuleInterface';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {AuthService} from './auth.service';


@Injectable()
export class MapService  {

  public module: ModuleInterface;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.module = new ModuleInterface('geoloc', this.httpClient, this.loaderService);
  }
}
