<header class="main-header d-flex justify-content-between align-items-center">
  <div>
    <a href="../" routerLink="/dashboard" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b>
    {{plan?.title}}
  </b>

  <div class="d-flex justify-content-end">
    <a style='
    color: white;
    text-decoration:none;
    font-size: 10px;
    padding: 4px 10px;' href="{{urlMapperBack}}map-A0/build/{{idMapA0}}.pdf?compression=light" target="_blank" class="pm-btn pm-btn-accent me-2">
      GENERER LE A0 light
    </a>
    <a style='
    color: white;
    text-decoration:none;
    font-size: 10px;
    padding: 4px 10px;' href="{{urlMapperBack}}map-A0/build/{{idMapA0}}.pdf" target="_blank" class="pm-btn pm-btn-accent me-2">
      GENERER LE A0
    </a>
  </div>
</header>

<div class="container">
  <div class="row">
    <div class="col-12">

      <div *ngIf="!!plan" class="plan-preview d-flex flex-row mt-5">

        <!-- COLUMN 1 -->
        <div class="d-flex flex-column plan-preview-column-1">
          <!-- 1 Titre -->
          <div (click)="renamePlan(plan)" class="plan-preview-block plan-preview-block-1 plan-preview-block-validate">
            <div class="p-2">
              <div class="plan-preview-tag">1</div>
              <div class="plan-preview-name">Titre</div>
            </div>
          </div>
          <div routerLink="/plan/{{idMapA0}}/2" class="d-flex flex-row plan-preview-block plan-preview-block-2 {{ stepsDone?.indexOf('echelle') > -1 ? 'plan-preview-block-validate' : '' }}">
            <!-- 2 Echelle de risque-->
              <div class="p-2">
                <div class="plan-preview-tag">2</div>
                <div class="plan-preview-name">Echelle de risque</div>
              </div>
          </div>

          <!-- 4 CCM -->
          <div routerLink="/plan/{{idMapA0}}/4" class="plan-preview-block plan-preview-block-4 {{ stepsDone?.indexOf('ccm') > -1 ? 'plan-preview-block-validate' : '' }}">
            <div class="p-2">
              <div class="plan-preview-tag">3</div>
              <div class="plan-preview-name">CCM</div>
            </div>
          </div>
        </div>
        <!-- /COLUMN 1 -->

        <!-- COLUMN 2 -->
        <div class="d-flex flex-column plan-preview-column-2">

          <!-- 7 LEGEND -->
          <div routerLink="/plan/{{idMapA0}}/7" class="plan-preview-block plan-preview-block-7 plan-preview-block-validate">
            <div class="p-2">
              <div class="plan-preview-tag">4</div>
              <div class="plan-preview-name">Légende</div>
            </div>
          </div>

          <!-- 8 Alerte communale -->
          <div routerLink="/plan/{{idMapA0}}/8" class="plan-preview-block plan-preview-block-8 {{ stepsDone?.indexOf('strategiecom') > -1 ? 'plan-preview-block-validate' : '' }}">
            <div class="p-2">
              <div class="plan-preview-tag">5</div>
              <div class="plan-preview-name">Alerte communale</div>
            </div>
          </div>



        </div>
        <!-- /COLUMN 2 -->

        <!-- COLUMN 3 -->
        <div class="d-flex flex-column plan-preview-column-3">

          <!-- 5 Carte principale -->
          <div routerLink="/plan/{{idMapA0}}/5" class="plan-preview-block plan-preview-block-5 {{ stepsDone?.indexOf('map') > -1 ? 'plan-preview-block-validate' : '' }}">
            <div class="p-2">
              <div class="plan-preview-tag">6</div>
              <div class="plan-preview-name">Gestion des cartes</div>
            </div>
          </div>


        </div>
        <!-- /COLUMN 3 -->


        <!-- COLUMN 4 -->
        <div class="d-flex flex-column plan-preview-column-4">

          <!-- 9 Enjeux et actions -->
          <div routerLink="/plan/{{idMapA0}}/9" class="plan-preview-block plan-preview-block-9  {{ stepsDone?.indexOf('legend_dynamic') > -1 ? 'plan-preview-block-validate' : '' }}">
            <div class="p-2">
              <div class="plan-preview-tag">7</div>
              <div class="plan-preview-name">Enjeux et actions</div>
            </div>
          </div>

          <!-- 10 Cartouche -->
          <div routerLink="/plan/{{idMapA0}}/10" class="plan-preview-block plan-preview-block-10  {{ stepsDone?.indexOf('entete') > -1 ? 'plan-preview-block-validate' : '' }}">
            <div class="p-2">
              <div class="plan-preview-tag">8</div>
              <div class="plan-preview-name">Cartouche</div>
            </div>
          </div>

        </div>
        <!-- /COLUMN 4 -->
      </div>
      <div *ngIf="!!plan" >
        <input style ='z-index:9000' id="file"  type="file"  #fileUpload>
      </div>
    </div>
  </div>
</div>
