import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PlanComponent} from './plan/plan.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {Map3dComponent} from './plan/map-3d/map-3d.component';
import {Map2dComponent} from './plan/map-2d/map-2d.component';
import {EchelleComponent} from './plan/echelle/echelle.component';
import {CcmComponent} from './plan/ccm/ccm.component';
import {EnjeuxComponent} from './plan/enjeux/enjeux.component';
import {LegendeComponent} from './plan/legende/legende.component';
import {StrategieCommunaleComponent} from './plan/strategie-communale/strategie-communale.component';
import {CartoucheComponent} from './plan/cartouche/cartouche.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from '../shared/auth.guard';
import {UnauthorizedComponent} from './unauthorized.component';
import {DeviceGuard} from '../shared/device.guard';
import {WrongDeviceComponent} from './wrong-device.component';
import {JwtLoginComponent} from './jwt-login.component';

const routes: Routes = [
  {
    component: LoginComponent,
    path: 'login',
    canActivate: [DeviceGuard]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'jwt-login',
    component: JwtLoginComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'wrong-device',
    component: WrongDeviceComponent
  },
  {
    component: DashboardComponent,
    path: 'dashboard',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: PlanComponent,
    path: 'plan/:id',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: PlanComponent,
    path: 'plan/:id',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: Map3dComponent,
    path: 'plan/:id/6/:type',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: Map2dComponent,
    path: 'plan/:id/5',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: EchelleComponent,
    path: 'plan/:id/2',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: CcmComponent,
    path: 'plan/:id/4',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: LegendeComponent,
    path: 'plan/:id/7',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: EnjeuxComponent,
    path: 'plan/:id/9',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: CartoucheComponent,
    path: 'plan/:id/10',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    component: StrategieCommunaleComponent,
    path: 'plan/:id/8',
    canActivate: [DeviceGuard, AuthGuard]
  },
  {
    path: 'plan/:id/label',
    loadChildren : () => import('./plan/label/label.module').then((m) => m.LabelModule),
    canActivate: [DeviceGuard, AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
