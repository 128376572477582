import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import * as Bowser from 'bowser';

@Injectable({
  providedIn: 'root',
})
export class DeviceGuard  {

  constructor(
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    return Bowser.mobile ? this.router.parseUrl('/wrong-device') : true ;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
