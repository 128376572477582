import {HttpClient} from '@angular/common/http';

export class EnvironmentConfiguration {
  public static urls: any;

  public static load(http: HttpClient): Promise<void> {
    return new Promise((resolve) => {
      http.get<any>('/assets/config/environment.json')
          .subscribe((config) => {
            EnvironmentConfiguration.urls = config;
            resolve();
          }, (e) => {
            if (e.status === 404) {
              console.error('Cand find environment.json in src/assets/config folder\n\n' +
                'Please create yours by coping src/assets/config/environment.example.json' +
                ' to src/assets/config/environment.json');
            } else {
              console.error(e);
            }
          });
    });
  }
}
