import {AfterViewInit, Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {A0, ModuleType, SnapshotType} from '../../../shared/entities/A0.entity';
import {MapperService} from '../../../services/mapper.service';
import {MapCesiumComponent} from '../../../shared/cesium/map-cesium.component';
import {CdkDrag, CdkDragEnd} from '@angular/cdk/drag-drop';
import {DownloadService} from '../../../services/download.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from '../../../services/loader.service';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {skipWhile} from 'rxjs/operators';

@Component({
  selector: 'app-plan-map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss']
})
export class Map2dComponent implements AfterViewInit {

  plan: A0;
  options = {
    dpi: 150
  };

  timestamp = this.getDate();
  listSnapshot = [];
  listModule = [];
  currentSnapshot;

  isLoadingLayer = false;
  isBuildingScreenShot = false;

  manageableLayers = [];

  public upload;

  public reservedItemModule: any = {
    MODULE_LEGENDE_STATIC: {
      initialY: 0,
      width: 26.32,
      height: 14.29,
      active: true
    },
    MODULE_STRATEGIE_ALERTE_COMMUNALE: {
      initialY: 0,
      width: 26.32,
      height: 14.29,
      active: true
    }
  };

  public freeTitles = [] ;

  public reservedItem: any = {
    ZOOM_1: {
      initialY: 28.58,
      width: 26.32,
      height: 35.71,
      active: false
    },
    ZOOM_2: {
      initialY: 64.27,
      width: 26.32,
      height: 35.71,
      active: false
    },
  };

  layersToLoad = [
    'road', 'hydroSurface', 'watercourse', 'railway'
  ];

  allLayersToLoad = [
    { alias: 'road', name: 'Routes'},
    { alias: 'railway', name: 'voies ferrées'},
    { alias: 'hydroSurface', name: `Surfaces en eau`},
    { alias: 'watercourse', name: `Cours d'eau`},
    { alias: 'places', name: 'Toponymes'},
  ];

  isInEdition = false;

  @ViewChild('moduleLegendeStatic') MODULE_LEGENDE_STATIC: CdkDrag;
  @ViewChild('moduleStrategieAlerteCommunale') MODULE_STRATEGIE_ALERTE_COMMUNALE: CdkDrag;
  @ViewChild('zoomArea1') ZOOM_1: CdkDrag;
  @ViewChild('zoomArea2') ZOOM_2: CdkDrag;
  @ViewChild('map') mapComponent: MapCesiumComponent;
  @ViewChildren(MatSlideToggle) layerToggle: QueryList<MatSlideToggle>;

  private;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public mapperService: MapperService,
    public downloadService: DownloadService,
    public loaderService: LoaderService,
    public snackbar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    this.getPlan(this.route.snapshot.paramMap.get('id'));
  }

  public getPlan(id): void {
    this.mapperService.getPlan(id).subscribe((plan) => {
      this.plan = plan;
      console.log('[Map2dComponent] -> (plan) -> ', plan)
      this.updateSnapshotList(id);
      this.updateModuleList(id);
    });
  }

  updateSnapshotList(id): void {
    this.mapperService.getSnapshotList(id).subscribe((listSnapshot) => {
      const _zoom = listSnapshot.filter(snapshot =>  snapshot.id_zoom.includes('ZOOM'));
      this.listSnapshot = _zoom;
      this.currentSnapshot = listSnapshot.find(a => a.id_zoom === 'MAIN');

      _zoom.forEach(zoom => {
        this.reservedItem[zoom.id_zoom] = {
          ...this.reservedItem[zoom.id_zoom],
          ...zoom,
          active: true,
        };
      });

      setTimeout(() => {
        for (const item in this.reservedItem) {
          if (this.reservedItem[item].active) {
            this.setReservedItemDimension(item);

            if (!!this.reservedItem[item].id_zoom) {
              this.reservedItem[item].convertedPosition = this.printPositionToCanvas(this.reservedItem[item].position);
            }
          }
        }
      });
    });
  }

  updateModuleList(id): void {
    this.mapperService.getModuleHotList(id).subscribe((listModule) => {
      console.log('list module', listModule);
      const module = listModule.filter(module => module.id_module);
      this.listModule = module;
      console.log('liste module', this.listModule);
      module.forEach(module => {

        if (module.id_module === 'MODULE_ALL_TITLES') {
          this.freeTitles = module.position.length > 0 ? JSON.parse(module.position) : [];
        }

        if (this.reservedItemModule[module.id_module]){
          this.reservedItemModule[module.id_module] = {
            ...this.reservedItemModule[module.id_module],
            ...module,
            active: true,
          };
        }
      });

      setTimeout(() => {

        this.printPositionTitlesToCanvas();

        for (const item in this.reservedItemModule) {
          if (this.reservedItemModule[item].active) {
            this.setReservedItemModuleDimension(item);

            if (!!this.reservedItemModule[item].id_module) {
              this.reservedItemModule[item].convertedPosition = this.printPositionToCanvas(this.reservedItemModule[item].position);
            }else{
              this.reservedItemModule[item].convertedPosition = this.printPositionToCanvas(MapCesiumComponent.INITIAL_POSITION[item]);
            }
          }
        }
      });
    });
  }

  screenshotEvent(obj): void {
    if (obj.type === 'success') {
      if (obj.data.type === 'MAIN') {
        this.currentSnapshot = obj.data;
        this.isInEdition = false;
      }
    }
  }

  getDate(): number {
    return Date.now();
  }

  public onFileSelected(event, type): void {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (type === 'background') {
      this.uploadCustomBackground(file);
    } else {
      this.uploadCustomZoom(file);
    }
  }

  loadAllLayer(): void {
    this.mapComponent.loadAllLayer(this.layersToLoad);
  }

  handleVisibility(item): void {
    item.layer.show = !item.layer.show;
    if (item.name === 'PCS') {
      item.layer.num.show = item.layer.show;
    }
  }
  handleNiveauxVisibility(item): void {
    item.layer.showNiveau = !item.layer.showNiveau ;
    if (item.name === 'ZMI') {
      this.mapComponent.previouslyLoadedZmi = [];
      this.mapComponent.buildZmi();
    } else if (item.name === 'ZMS') {
      this.mapComponent.previouslyLoadedZms = [];
      this.mapComponent.buildZms();
    }

  }
  mapIsInit(): void {
    this.mapComponent.zmi.showNiveau = true;
    this.mapComponent.zms.showNiveau = false;
    this.manageableLayers = [
      {name: 'Limite communale', layer: this.mapComponent.polygon, alias: 'polygon'},
      {name: 'ZMI', layer: this.mapComponent.zmi, alias: 'zmi'},
      {name: 'ZMS', layer: this.mapComponent.zms, alias: 'zms'},
      {name: 'PCS', layer: this.mapComponent.picto, alias: 'picto', collapsed: false},
      {name: 'Routes', layer: this.mapComponent.road, alias: 'road'},
      {name: 'voies ferrées', layer: this.mapComponent.railway, alias: 'railway'},
      {name: 'Surfaces en eau', layer: this.mapComponent.hydroSurface, alias: 'hydroSurface'},
      {name: 'Cours d’eau', layer: this.mapComponent.watercourse, alias: 'watercourse'},
      {name: 'Toponymes', layer: this.mapComponent.places, alias: 'places'},
    ];
  }

  dragEnd(event: CdkDragEnd): void {
    const item = event.source.element.nativeElement.id;

    const {x, y} = this.canvasToPrintPosition(item);
    console.log({x, y});
    this.mapperService.createOrUpdateSnapshot(this.plan, {
      type: item as SnapshotType,
      position: `${x},${y}`,
      snapshot_url_image: this.reservedItem[item].snapshot_url_image,
      bbox: this.reservedItem[item].bbox,
      is_custom_upload: this.reservedItem[item].is_custom_upload
    }).subscribe((res) => {
      console.log('[Map2dComponent] -> moved() ->, yolo ');
    });
  }

  dragEndModule(event: CdkDragEnd): void {
    const item = event.source.element.nativeElement.id;
    const {x, y} = this.canvasToPrintPosition(item);
    console.log({x, y});
    this.mapperService.createOrUpdateModule(this.plan, {
      type: item as ModuleType,
      position: `${x},${y}`
    }).subscribe((res) => {
      console.log('[Map2dComponent] -> moved() -> ');
    });
  }

  dragEndTitles(event: CdkDragEnd): void {
    // get only the id of the title

    const id_title: number = parseInt (event.source.element.nativeElement.id.split('_')[1]);
    const {x, y} = this.canvasToPrintPositionTitles(event);
    this.assignPositionTitle(id_title, x, y);
    this.mapperService.createOrUpdateModule(this.plan, {
      type: 'MODULE_ALL_TITLES' as ModuleType,
      position: JSON.stringify(this.freeTitles)
    }).subscribe((res) => {
      console.log('[Map2dComponent] -> moved() -> ');
    });
  }

  addZoom(): void {
      const listId = this.listSnapshot.map(a => a.id_zoom);

      if (listId.includes(SnapshotType.ZOOM_1)) {
        this.router.navigate([`/plan/${this.plan.id}/6/ZOOM_2`]);
      } else if (listId.includes(SnapshotType.ZOOM_2) || listId.length === 0) {
        this.router.navigate([`/plan/${this.plan.id}/6/ZOOM_1`]);
      }
  }

  uploadCustomZoom(file): void {
    this.loaderService.startLoading('zoom-upload');
    const listId = this.listSnapshot.map(a => a.id_zoom);
    const zoomType: SnapshotType = listId.includes(SnapshotType.ZOOM_1)
      ? SnapshotType.ZOOM_2
      : listId.includes(SnapshotType.ZOOM_2) || listId.length === 0
        ? SnapshotType.ZOOM_1 : SnapshotType.ZOOM_2;

    this.downloadService.uploadSnapshot(file, this.plan.id, zoomType).subscribe((res) => {
      this.upload = res;

      if (res.state === 'DONE' && !!res.content.url) {
        this.upload = null;
        this.mapperService.createOrUpdateSnapshot(this.plan, {
          type: zoomType,
          snapshot_url_image: res.content.url,
          position: MapCesiumComponent.INITIAL_POSITION[zoomType],
          bbox: '',
          is_custom_upload: true
        }).subscribe((_res) => {
          this.updateSnapshotList(this.plan.id);
          this.loaderService.stopLoading('zoom-upload');
        });
      }
    }, (err) => {
      this.loaderService.stopLoading('zoom-upload');

      this.snackbar.open(`Un problème est survenue pendant l'envoi du fichier`, 'ok', {
        duration: 3000
      });
    });
  }

  uploadCustomBackground(file): void {
    this.downloadService.uploadSnapshot(file, this.plan.id, SnapshotType.MAIN).subscribe((res) => {
      this.upload = res;
      if (res.state === 'DONE' && !!res.content.url) {
        this.upload = null;
        this.mapperService.createOrUpdateSnapshot(this.plan, {
          type: SnapshotType.MAIN,
          snapshot_url_image: res.content.url,
          snapshot_url_image_lite: res.content.url,
          position: '0,0',
          bbox: '',
          is_custom_upload: true
        }).subscribe((_res) => {
          this.updateSnapshotList(this.plan.id);
        });
      }
    }, (err) => {
      this.snackbar.open(`Un problème est survenue pendant l'envoi du fichier`, 'ok', {
        duration: 3000
      });
    });
  }

  setAllReservedInitialPosition(): void {
    for (const item in this.reservedItem) {
      if (this.reservedItem[item].active) {
        this.setInitialPosition(item);
      }
    }
  }

  setInitialPosition(item): void {
    const containerHeight = this.mapComponent.map.container.style.height.replace('px', '');

    this.reservedItem[item].convertedPosition = {x: 0, y: (containerHeight / 100) * this.reservedItem[item].initialY};

    setTimeout(() => {
      const {x, y} = this.canvasToPrintPosition(item);

      this.mapperService.createOrUpdateSnapshot(this.plan, {
        type: item as SnapshotType,
        position: `${x},${y}`,
        snapshot_url_image: this.reservedItem[item].snapshot_url_image,
        bbox: this.reservedItem[item].bbox,
      }).subscribe((res) => {
        console.log('[Map2dComponent] -> moved() -> ', res);
      });

      this.mapperService.createOrUpdateModule(this.plan, {
        type: item as ModuleType,
        position: `${x},${y}`
      }).subscribe((res) => {
        console.log('[Map2dComponent] -> moved() -> ', res);
      });
    });
  }

  delete(zoom): void {
    this.mapperService.deleteSnapshot(this.plan.id, zoom).subscribe(() => {
      if (this.reservedItem[zoom]) {
        this.reservedItem[zoom].active = false;
      }

      this.updateSnapshotList(this.plan.id);
    });
  }

  focusZoom(item): void {
    this[item].nativeElement.classList.add('focused');
  }

  blurZoom(item): void {
    this[item].nativeElement.classList.remove('focused');
  }

  printPositionToCanvas(dimension): any {
    const [elX, elY] = dimension.split(',');

    const printWidth = 13465;
    const printHeight = 9921;

    const container = this.getContainerDimension();

    return {
      x: elX / (printWidth / container.width),
      y: elY / (printHeight / container.height)
    };
  }

  canvasToPrintPosition(item): any {
    const [elX, elY] = this.getItemCanvasPosition(item);

    const printWidth = 13465;
    const printHeight = 9921;

    const container = this.getContainerDimension();

    return {
      x: elX * (printWidth / container.width),
      y: elY * (printHeight / container.height)
    };
  }

  getItemCanvasPosition(item): any {
    const transform = this[item].nativeElement.style.transform;
    return transform.match(/[0-9]*px/gm).map(a => a.replace('px', ''));
  }

  getContainerDimension(): any {
    return {
      width: Number(this.mapComponent.map.container.style.width.replace('px', '')),
      height: Number(this.mapComponent.map.container.style.height.replace('px', ''))
    };
  }

  setReservedItemDimension(type): void {
    const containerHeight = Number(this.mapComponent.map.container.style.height.replace('px', ''));
    const containerWidth = Number(this.mapComponent.map.container.style.width.replace('px', ''));
    const item = this.reservedItem[type];
    const el = this[type].nativeElement;

    el.style.width = `${(containerWidth / 100) * item.width}px`;
    el.style.height = `${(containerHeight / 100) * item.height}px`;
  }

  setReservedItemModuleDimension(type): void {
    const containerHeight = Number(this.mapComponent.map.container.style.height.replace('px', ''));
    const containerWidth = Number(this.mapComponent.map.container.style.width.replace('px', ''));
    const item = this.reservedItemModule[type];
    const el = this[type].nativeElement;

    el.style.width = `${(containerWidth / 100) * item.width}px`;
    el.style.height = `${(containerHeight / 100) * item.height}px`;
  }

  print2d(): void {
    const activeLayer = this.layerToggle.filter(a => a.checked).map(a => a.name);
    this.mapComponent.build2dScreenshot(activeLayer);
  }

  updatePCSpictoSize(event, item): any {
    item.layer.buildPCS(event.value);
  }

  deleteTitle(id: number) {
    this.freeTitles = this.freeTitles.filter(title => title.id !== id);
    this.mapperService.createOrUpdateModule(this.plan, {
      type: 'MODULE_ALL_TITLES' as ModuleType,
      position: JSON.stringify(this.freeTitles)
    }).subscribe((res) => {
      console.log('[Map2dComponent] -> delete(freeTitles) -> ', res, id)
    });
  }

  addTitle() {
    const newTitle = {
      id: this.freeTitles.length + 1,
      name: 'titre ' + (this.freeTitles.length + 1),
      position: {x: 0, y: 0},
      color: '#ffffff',
      fontSize: 8,
      shadow: true,
    };
    this.freeTitles.push(newTitle);
  }

  updateTitle(target: EventTarget, title) {
    const checked = (target as HTMLInputElement).checked;
    const value = (target as HTMLInputElement).value;
    const name = (target as HTMLInputElement).name;
    if (typeof name !== 'undefined') {
      title[name] = value;
    }
    title[name] = value;
    if (typeof checked !== 'undefined' && typeof name === 'undefined') {
      title.shadow = checked;
    }
    this.mapperService.createOrUpdateModule(this.plan, {
      type: 'MODULE_ALL_TITLES' as ModuleType,
      position: JSON.stringify(this.freeTitles)
    }).subscribe((res) => {
      this.snackbar.open('Titre mis à jour', 'ok', {
        duration: 3000
      });
      console.log('[Map2dComponent] -> moved() -> ntm');
    });
  }

  private canvasToPrintPositionTitles(event: CdkDragEnd) {

     const [elX, elY] = this.getItemCanvasPositionTitle(event);

     const printWidth = 13465;
     const printHeight = 9921;

     const container = this.getContainerDimension();

     return {
      x: parseInt(elX) * (printWidth / container.width),
      y: parseInt(elY) * (printHeight / container.height)
    };


  }
  getItemCanvasPositionTitle(event: CdkDragEnd) {
    const transform = event.source.element.nativeElement.style.transform;
    return transform.match(/[0-9]*px/gm).map(a => a.replace('px', ''));
  }

  private assignPositionTitle(item: number, x: number, y: number) {
     this.freeTitles.forEach(title => {
       delete title.convertedPosition ;
       if (item == title.id) {
         title.position = {x, y};
       }
     });
  }

  private printPositionTitlesToCanvas() {
    for (const title in this.freeTitles) {

      const elX = this.freeTitles[title].position.x;
      const elY = this.freeTitles[title].position.y;

      const printWidth = 13465;
      const printHeight = 9921;

      const container = this.getContainerDimension();

      const x_canvas = elX / (printWidth / container.width);
      const y_canvas = elY / (printHeight / container.height);

      this.freeTitles[title].convertedPosition = {x: x_canvas, y: y_canvas};

    }
  }
}
