<header class="main-header d-flex justify-content-between align-items-center">
  <div>
    <a  routerLink="/plan/{{idMapA0}}" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b class="flex-fill text-center">
    Cartouche de {{plan?.title}}
  </b>

  <div class="d-flex justify-content-end me-2">
    <button class="pm-btn pm-btn-primary me-2" (click)="openModal()">Modifier la cartouche</button>

    <app-steps [type]="'entete'"></app-steps>
  </div>
</header>
<div class="cartouche">

  <div class="cartouche-content p-3">
    <h2 class="title d-flex justify-content-between mt-2">
      Logo personnalisé

      <button class="pm-btn pm-btn-primary cartouche-add" (click)="fileUpload.click()"
              [disabled]="listLogo.length === 3 || isCustom || !plan">
        <i class="bi bi-plus"></i>
        Ajouter un logo
      </button>

      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    </h2>



    <div class="mt-3 mb-3">
      <div class="cartouche-logo" *ngFor="let logo of listLogo">
        <img [src]="logo.url" alt="logo personnalisé">
        <div class="cartouche-logo-edit mt-3">
          <button class="btn btn-outline-danger w-100 bi-trash" (click)="deleteCartoucheLogo(logo.id)">
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isCustom" class="alert alert-warning">
      <p>
        <i class="bi bi-info-circle-fill me-2"></i>
        La personnalisation de logo n'est pas disponible pour les cartouches manuellement importer
      </p>
      <ng-template [ngIf]="listLogo.length">
        <hr>
        <p>
          Les logos présents ne sont pas pris en compte
        </p>
      </ng-template>
      <button class="btn btn-warning" (click)="resetPdf()">Réinitialiser le pdf</button>
    </div>
  </div>

  <div class="cartouche-iframe">
    <iframe *ngIf="urlIframe" [src]='urlIframe'></iframe>
  </div>
</div>
