import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: 'generic.dialog.component.html'
})
export class GenericDialogComponent {

  public title = '';
  public content = '';
  public btnCancel = '';
  public btnConfirm = '';
  public btnConfirmClass = '';
  public btnCancelClass = '';
  public shouldReturn: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      content: string,
      shouldReturn: boolean,
      btnCancel?: string,
      btnConfirm?: string
      btnConfirmClass?: string
      btnCancelClass?: string
    },
  ) {
    this.title = data.title;
    this.content = data.content;
    this.shouldReturn = data.shouldReturn;

    this.btnCancel = data.btnCancel ? data.btnCancel : 'Annuler';
    this.btnCancelClass = data.btnCancelClass ? data.btnCancelClass : 'btn btn-primary';

    this.btnConfirm = data.btnConfirm ? data.btnConfirm : 'Ok';
    this.btnConfirmClass = data.btnConfirmClass ? data.btnConfirmClass : 'btn btn-outline-secondary';
  }
}

