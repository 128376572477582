import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public auth = {
    login: '',
    password: ''
  };

  error = '';
  isLoading = false;

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  login(): void {
    this.isLoading = true;
    this.error = '';

    this.authService.tryLogin(this.auth.login, this.auth.password).subscribe(isValid => {
      this.router.navigate([this.authService.redirectUrl]).then(() => {
        this.authService.redirectUrl = '';
      });
    }, () => {
      this.error = 'identifiant invalide';
      this.isLoading = false;
    });
  }
}
