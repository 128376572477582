import {Component} from '@angular/core';

@Component({
  template: `
    <div class="d-flex justify-content-center align-items-center h-100 p-3" >
      <div class="alert alert-danger" role="alert" [style.width.px]="400">
        <h4 class="alert-heading fw-bold">Appareil non compatible</h4>
        <p>Le predict mapper n’est pas compatible sur mobile. Veuillez utiliser un ordinateur de bureau.</p>
      </div>
    </div>
  `,
})
export class WrongDeviceComponent {
  constructor() {}
}

