import {Injectable} from '@angular/core';
import {ModuleInterface} from '../shared/ModuleInterface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class IgoService  {

  public module: ModuleInterface;
  public headers: HttpHeaders;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.module = new ModuleInterface('mapper', this.httpClient, this.loaderService, true);
    this.module.addAuthorizationInAllRequest();
  }

  getPcs(codeZone, typeZone= 'FRA', typePlan = 1): Observable<any> {
    return this.module.get(`pcs/${typeZone}/${codeZone}/${typePlan}`);
  }
}
