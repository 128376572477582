import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EnvironmentConfiguration} from '../../shared/EnvironmentConfiguration';
import {MapperService} from '../../services/mapper.service';
import {A0} from '../../shared/entities/A0.entity';
import {TitleDialogComponent} from './title/title.dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  plan: A0 = null;
  jsonBuild: any ;
  idMapA0: string = this.route?.snapshot.params.id;
  urlMapperBack: string = EnvironmentConfiguration.urls.mapper;
  stepsDone: any;

  constructor(
    public route: ActivatedRoute,
    public mapperService: MapperService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.idMapA0 = this.route?.snapshot.params.id;

    this.getPlan(this.idMapA0);
    this.getStepsDone(this.idMapA0);

  }

  public getPlan(id): void {
    this.mapperService.getPlan(id).subscribe(plan => {
      this.plan = plan;
    });
  }

  public renamePlan(a0: A0): void {
    this.dialog.open(TitleDialogComponent, {
      data: {
        plan: a0
      }
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.plan = update;
      }
    });
  }

  public getStepsDone(id): void {
    this.mapperService.getStepsDone(id).subscribe(data => {
      this.stepsDone = data.steps_dones;
    });
  }


}
