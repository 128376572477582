export enum EntityType {
  Marker = 'marker',
  Digue = 'digue',
  Route = 'route',
}

const setEntityTypeBasedOnAlias = (alias) => {
  switch (alias) {
    case 'digue':
      return EntityType.Digue;
    case 'fermeture_route':
    case 'itineraire_evacuation':
    case 'deneigement':
      return EntityType.Route;
    default:
      return EntityType.Marker;
  }
}

export class PlanEntity {
  points: any[] = [];
  lines: any[] = [];

  filter: any = {};
  filterBy = [];

  name = '';
  alias = '';
  properties: any = {};
  type: EntityType;
  from = '';

  featureGroup: any = [];

  show = true;

  _map;

  constructor(alias, from?) {
    this.alias = alias;
    this.from = from;
    this.type = setEntityTypeBasedOnAlias(alias);
  }

  eachLayers(callback) {
    this.points.forEach(callback);
    this.lines.forEach(callback);
  }

  addLayer(geometryType, layer) {
    switch (geometryType) {
      case 'Point':
        this.points.push(layer);
        break;
      case 'LineString':
      case 'MultiLineString':
        this.lines.push(layer);
        break;
    }
  }

  setFilter(section, name, active, sort = 0) {
    this.filter[section] = {
      ...this.filter[section],
      name,
      active,
      sort,
      id: `${section}-${name}`
    }

    this.filterBy.push( this.filter[section].id);
  }
}
