<header class="main-header d-flex flex-wrap justify-content-between align-items-center">
  <div >
    <a routerLink="/plan/{{idMapA0}}" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b class="flex-fill text-center">
    Echelle de risque de {{plan?.title}}
  </b>

  <div  class="d-flex justify-content-end me-2">
    <a class="pm-btn pm-btn-primary me-2" [href]='urlIframe' target="_blank">Aperçu dans le navigateur</a>
    <button class="pm-btn pm-btn-primary me-2" (click)="openModal()">Modifier l'échelle de risque</button>
    <app-steps [type]="'echelle'"></app-steps>
  </div>
</header>

<div class="echelle-container d-flex overflow-hidden">

    <div class="flex-fill p-3 overflow-y-auto">
      <ng-template [ngIf]="isDefaultUrlEchelle">
        <div class="d-flex">
          <div class="d-flex justify-content-center  border-1 border-bottom w-100">
            <button [ngClass]="showEchelle?'echelle-btn-switch':'echelle-btn-switch-inactive'"
                    (click)='switchToEchelle()'>Echelle
            </button>
            <button [ngClass]="showConsigne?'echelle-btn-switch':'echelle-btn-switch-inactive'"
                    (click)='switchToConsigne()'>Consignes
            </button>
            <button *ngIf="echelle?.echelle_datas?.is_older_echelle" [ngClass]="showArchiveRecommendation?'echelle-btn-switch':'echelle-btn-switch-inactive'"
                    (click)='switchToArchiveRecommendation()'>Anciennes consignes
            </button>
          </div>
        </div>
        <div *ngIf="showEchelle">
          <div class="echelle-block">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph">Les événements</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addEvent()">+ Ajouter un nouvel événement</a>
              </div>
            </div>
            <div  *ngFor="let event of echelle?.echelle_datas.events; let index=index"
                  class="d-flex flex-wrap gap-3 align-items-start m-3 hide-form-field-helper"
                  [ngClass]="{
                'border-1 border-bottom border-opacity-25 pb-2': index !== echelle?.echelle_datas.events.length - 1
              }">

              <mat-form-field [style.width.px]="150" appearance="outline">
                <mat-label>Titre</mat-label>
                <input matInput [(ngModel)]="event.title" name="title" (change)="updateEchelle()">
              </mat-form-field>


              <mat-form-field [style.width.px]="80" appearance="outline">
                <mat-label>Police</mat-label>
                <input matInput type="number" value="15" name="fontSize" [(ngModel)]="event.fontSize" min="10"
                       (change)="updateEchelle()">
              </mat-form-field>


              <mat-form-field [style.width.px]="80" appearance="outline" class="force-label-visibility">
                <mat-label [style.max-width.px]="50">Couleur</mat-label>
                <mat-select [(ngModel)]="event.color" name="color" (selectionChange)="updateEchelle()"
                            [ngStyle]="{backgroundColor: event.color, borderRadius: '4px'}">
                  <mat-option value="#000000" selected>
                    <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#ABA8AB">
                    <div class="echelle-picker-color" style="background-color:#ABA8AB">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#673A8B">
                    <div class="echelle-picker-color" style="background-color:#673A8B">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#2846AA">
                    <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#E32322">
                    <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#EA661E">
                    <div class="echelle-picker-color" style="background-color:#EA661E">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#F4C300">
                    <div class="echelle-picker-color" style="background-color:#F4C300">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#29A22D">
                    <div class="echelle-picker-color" style="background-color:#29A22D">&nbsp;</div>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [style.width.px]="150" appearance="outline">
                <mat-label>Sous-titre</mat-label>
                <input matInput name="subtitle" [(ngModel)]="event.subtitle" (change)="updateEchelle()">
              </mat-form-field>

              <mat-form-field [style.width.px]="80" appearance="outline">
                <mat-label>Police</mat-label>
                <input matInput type="number" value='10' name="fontSize" [(ngModel)]="event.fontSizeSubtitle" min="10"
                       (change)="updateEchelle()">
              </mat-form-field>

              <div class="echelle-slider">
                <div class="echelle-text-positionY">Position-verticale</div>
                <mat-slider (change)="updateEchelle()"
                            [max]="100"
                            [min]="0"
                            [step]="1">
                  <input matSliderThumb [(ngModel)]="event.positionY" />
                </mat-slider>
                <input [(ngModel)]="event.positionY"
                       (change)="updateEchelle()"
                       type="number"
                       class="echelle-num-positionY"
                       name="positionY">
              </div>

              <button class="echelle-button-delete" (click)="deleteEvent(index)">
                <i class="bi-x" style="font-size: 2rem; color: #f42272;"></i>
              </button>
            </div>
          </div>



          <div class="echelle-block">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph">Les paramètres</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addParameters()">+ Ajouter un paramètre</a>
              </div>
            </div>


            <div class="d-flex flex-wrap gap-3 align-items-start m-3 hide-form-field-helper"
                 [ngClass]="{
                'border-1 border-bottom border-opacity-25 pb-2': index !== echelle?.echelle_datas.parameters.length - 1
             }"
                 *ngFor="let parameter of echelle?.echelle_datas.parameters;let index=index">


              <mat-form-field [style.width.px]="180" appearance="outline">
                <mat-label>Image</mat-label>
                <mat-select [(ngModel)]="parameter.image"  panelWidth="260" (selectionChange)="updateEchelle()">
                  <mat-option value="" selected></mat-option>
                  <mat-option *ngFor="let picto of listPicto" value="{{picto.alias}}">
                    <img src="{{urlPictos}}picto/inforisque/{{picto.alias}}.svg?cursor=false&border=#673a8b&border-width=5&fg=#673a8b"
                         width="25px" height="25px"/> {{ picto.name_fr }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field [style.width.px]="150" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="3"
                          [(ngModel)]="parameter.description"
                          (change)="updateEchelle()"
                ></textarea>
              </mat-form-field>

              <mat-form-field [style.width.px]="80" appearance="outline">
                <mat-label>Police</mat-label>
                <input matInput type="number" value='' [(ngModel)]="parameter.fontSize" min="5"
                       (change)="updateEchelle()">
              </mat-form-field>



              <mat-form-field [style.width.px]="80" appearance="outline" class="force-label-visibility">
                <mat-label [style.max-width.px]="50">Couleur</mat-label>
                <mat-select [(ngModel)]="parameter.color" (selectionChange)="updateEchelle()"
                            [ngStyle]="{backgroundColor: parameter.color, borderRadius: '4px'}">
                  <mat-option value="#000000">
                    <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#ABA8AB">
                    <div class="echelle-picker-color" style="background-color:#ABA8AB">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#673A8B">
                    <div class="echelle-picker-color" style="background-color:#673A8B">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#2846AA">
                    <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#E32322">
                    <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#EA661E">
                    <div class="echelle-picker-color" style="background-color:#EA661E">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#F4C300">
                    <div class="echelle-picker-color" style="background-color:#F4C300">&nbsp;</div>
                  </mat-option>
                  <mat-option value="#29A22D">
                    <div class="echelle-picker-color" style="background-color:#29A22D">&nbsp;</div>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [style.width.px]="150" appearance="outline">
                <mat-label>Sous-description</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="3"
                          [(ngModel)]="parameter.subdescription"
                          (change)="updateEchelle()"
                ></textarea>
              </mat-form-field>


              <mat-form-field [style.width.px]="80" appearance="outline">
                <mat-label>Police</mat-label>
                <input matInput type="number" value='' [(ngModel)]="parameter.fontSizeSubdescription" min="5"
                       (change)="updateEchelle()">
              </mat-form-field>

              <div class="echelle-asterisk">
                <mat-checkbox class="example-margin" [(ngModel)]="parameter.asterisk"
                              (change)="updateEchelle()">Ajouter *
                </mat-checkbox>
              </div>

              <div class="echelle-slider">
                <div class="echelle-text-positionY">Position-verticale</div>
                <mat-slider (change)="updateEchelle()"
                            [max]="100"
                            [min]="0"
                            [step]="1">
                  <input matSliderThumb [(ngModel)]="parameter.positionY" />
                </mat-slider>
                <input [(ngModel)]="parameter.positionY"
                       (change)="updateEchelle()"
                       type="number"
                       class="echelle-num-positionY"
                       name="positionY">
              </div>

              <button class="echelle-button-delete" aria-label="Suprimmer le paramètre"
                      (click)="deleteParameter(index)">
                <i class="bi-x" style="font-size: 2rem; color: #f42272;"></i>
              </button>

            </div>
          </div>
        </div>

        <!--    ALL RECOMMANDATION-->
        <div class="echelle-width-recommandations" *ngIf="showConsigne">
          <!--      ONE RECOMMANDATION POST-CRISE-->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-grey">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">

              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-grey">Post-Crise</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.postCrise)">+
                  Ajouter une ligne Post-Crise</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-grey">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number"
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.postCrise.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number"
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.postCrise.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-grey" aria-label="Reset des consignes du block Post-Crise"
                                (click)="resetConsigneBlock('PostCrise')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-grey">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let post_crise of echelle?.echelle_datas.recommendations_new.postCrise; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="post_crise.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="post_crise.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let post_crise of echelle?.echelle_datas.recommendations_new.postCrise; let index=index">

                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="post_crise.consigne.fontSize" min="5"
                             (change)="updateEchelle()">

                      <div class="col echelle-recommendation-input-color">
                        <mat-select [(ngModel)]="post_crise.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: post_crise.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="post_crise.consigne.pos_y"
                             (change)="updateEchelle()">
                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-grey" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.postCrise,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION POST-CRISE-->
          <!--      ONE RECOMMANDATION SECURISATION MAXIMAL-->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-violet">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-violet">Securisation Maximale</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.securisationMaximale)">+
                  Ajouter une ligne Sécurisation Maximale</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-violet">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.securisationMaximale.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.securisationMaximale.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-violet" aria-label="Reset des consignes du block Sécurité Maximale"
                                (click)="resetConsigneBlock('securisationMaximale')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-violet">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let securisation_maximale of echelle?.echelle_datas.recommendations_new.securisationMaximale; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="securisation_maximale.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="securisation_maximale.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let securisation_maximale of echelle?.echelle_datas.recommendations_new.securisationMaximale; let index=index">
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="securisation_maximale.consigne.fontSize" min="5"
                             (change)="updateEchelle()">
                      <div class="col echelle-recommendation-input-color">
                        <mat-select matNativeControl [(ngModel)]="securisation_maximale.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: securisation_maximale.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>

                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="securisation_maximale.consigne.pos_y"
                             (change)="updateEchelle()">

                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-violet" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.securisationMaximale,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION SECURISATION MAXIMALE-->
          <!--      ONE RECOMMANDATION SECURISATION -->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-red">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-red">Securisation</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.securisation)">+
                  Ajouter une ligne Sécurisation</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-red">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.securisation.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.securisation.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-red" aria-label="Reset des consignes du block Sécurisation"
                                (click)="resetConsigneBlock('securisation')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-red">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let securisation of echelle.echelle_datas?.recommendations_new.securisation; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="securisation.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="securisation.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let securisation of echelle?.echelle_datas.recommendations_new.securisation; let index=index">
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="securisation.consigne.fontSize" min="5"
                             (change)="updateEchelle()">
                      <div class="col echelle-recommendation-input-color">
                        <mat-select matNativeControl [(ngModel)]="securisation.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: securisation.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>

                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="securisation.consigne.pos_y"
                             (change)="updateEchelle()">

                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-red" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.securisation,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION SECURISATION -->
          <!--      ONE RECOMMANDATION MOBILISATION -->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-orange">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-orange">Mobilisation</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.mobilisation)">+
                  Ajouter une ligne Mobilisation</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-orange">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.mobilisation.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.mobilisation.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-orange" aria-label="Reset des consignes du block Mobilisation"
                                (click)="resetConsigneBlock('mobilisation')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-orange">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let mobilisation of echelle?.echelle_datas.recommendations_new.mobilisation; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="mobilisation.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="mobilisation.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let mobilisation of echelle?.echelle_datas.recommendations_new.mobilisation; let index=index">
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="mobilisation.consigne.fontSize" min="5"
                             (change)="updateEchelle()">
                      <div class="col echelle-recommendation-input-color">
                        <mat-select matNativeControl [(ngModel)]="mobilisation.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: mobilisation.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>

                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="mobilisation.consigne.pos_y"
                             (change)="updateEchelle()">

                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-orange" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.mobilisation,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION MOBILISATION -->
          <!--      ONE RECOMMANDATION VIGILANCE -->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-yellow">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-yellow">Vigilance</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.vigilance)">+
                  Ajouter une ligne Vigilance</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-yellow">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.vigilance.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.vigilance.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-yellow" aria-label="Reset des consignes du block Vigilance"
                                (click)="resetConsigneBlock('vigilance')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-yellow">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let vigilance of echelle?.echelle_datas.recommendations_new.vigilance; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="vigilance.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="vigilance.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let vigilance of echelle?.echelle_datas.recommendations_new.vigilance; let index=index">
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="vigilance.consigne.fontSize" min="5"
                             (change)="updateEchelle()">
                      <div class="col echelle-recommendation-input-color">
                        <mat-select matNativeControl [(ngModel)]="vigilance.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: vigilance.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="vigilance.consigne.pos_y"
                             (change)="updateEchelle()">
                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-yellow" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.vigilance,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION VIGILANCE -->
          <!--      ONE RECOMMANDATION VEILLE -->
          <div class="echelle-one-block-recommandation echelle-one-block-recommandation-green">
            <div class="d-flex m-3 mb-4 border-1 border-bottom border-opacity-25">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-green">Veille</p>
              </div>
              <div class="echelle-add-action d-flex justify-content-end">
                <a class="echelle-text-ajout" (click)="addConsigne(echelle.echelle_datas.recommendations_new.veille)">+
                  Ajouter une ligne Veille</a>
              </div>
            </div>
            <div class = "block-parametre">
              <div class="container ">
                <div>
                  <button class="button-parametre button-parametre-green">PARAMETRE BLOC</button>
                </div>
                <div class="row text-center" >
                  <div class="col-7 bloc-label-param">
                    <div class="row">
                      <div class="col-4">
                        <label class="echelle-label">MARGE VERTICALE</label>
                      </div>
                      <div class="col-4">
                        <label class="echelle-label">INTERLIGNAGE</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-7 bloc-data-param" style="background-color:#f4f4f4; padding:10px" >
                    <div class="row input-group ml-3">
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.veille.consigne.deltaY"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <input class="form-control" type="number" value=''
                               [(ngModel)]="echelle.echelle_datas.recommendations_new.positionsBlock.veille.consigne.interligne"
                               min="0"
                               (change)="updateEchelle()">
                      </div>
                      <div class="col-4">
                        <button class="echelle-recommendation-button-reset echelle-recommendation-button-reset-green" aria-label="Reset des consignes du block Veille"
                                (click)="resetConsigneBlock('veille')">
                          Réinitialiser le block
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-contenu">
              <div class="container block-form-contenu">
                <div><button class="button-parametre button-parametre-green">CONTENU</button></div>
                <div class="row">
                  <div class="col-8 contenu-info-text">
                    <div class="row">
                      <div class="col-9 contenu-info-text-titre">CONSIGNE</div>
                      <div class="col-3 contenu-info-text-titre-decalage">ACTEUR</div>
                    </div>
                    <div *ngFor="let veille of echelle?.echelle_datas.recommendations_new.veille; let index=index">
                      <div class="row input-group">
                        <div class="col-8">
                          <input class="form-control m-1 custom-input" placeholder="" value=""
                                 [(ngModel)]="veille.consigne.text"
                                 (change)="updateEchelle()">
                        </div>
                        <div class="col-4">
                          <input class="form-control m-1" placeholder="" value=""
                                 [(ngModel)]="veille.actor.text"
                                 (change)="updateEchelle()">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 contenu-info-position">
                    <div class="row">
                      <div class="col-3 contenu-info-param-titre">POLICE</div>
                      <div class="col-3 contenu-info-param-titre">COULEUR</div>
                      <div class="col-5 contenu-info-param-titre">MARGE VERT.</div>
                    </div>
                    <div class="row" *ngFor="let veille of echelle?.echelle_datas.recommendations_new.veille; let index=index">
                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="veille.consigne.fontSize" min="5"
                             (change)="updateEchelle()">
                      <div class="col echelle-recommendation-input-color">
                        <mat-select matNativeControl [(ngModel)]="veille.consigne.color"
                                    (selectionChange)="updateEchelle()"
                                    [ngStyle]="{backgroundColor: veille.consigne.color||'#000000'}">
                          <mat-option value="#000000" selected></mat-option>
                          <mat-option value="#000000">
                            <div class="echelle-picker-color" style="background-color:#000000">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#2846AA">
                            <div class="echelle-picker-color" style="background-color:#2846AA">&nbsp;</div>
                          </mat-option>
                          <mat-option value="#E32322">
                            <div class="echelle-picker-color" style="background-color:#E32322">&nbsp;</div>
                          </mat-option>
                        </mat-select>
                      </div>

                      <input class="col form-control m-1" type="number" value='' [(ngModel)]="veille.consigne.pos_y"
                             (change)="updateEchelle()">

                      <div class="col px-0">
                        <button class="echelle-recommendation-button-delete echelle-recommendation-button-delete-green" aria-label="Suprimmer le paramètre"
                                (click)="deleteLigneConsigne(echelle.echelle_datas.recommendations_new.veille,index)">
                          <i class="bi-x croix-delete"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  END ONE RECOMMANDATION VEILLE -->
        </div>

        <div *ngIf="showArchiveRecommendation">
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-grey">Post-Crise</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.postCrise?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorPostCrise?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-violet">Sécurisation maximale</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.securisationMaximale?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorSecurisationMaximale?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-red">Sécurisation</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.securisation?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorSecurisation?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-orange">Mobilisation</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.mobilisation?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorMobilisation?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-yellow">Vigilance</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.vigilance?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorVigilance?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="echelle-block">
            <div class="echelle-border-bottom m-3">
              <div class="echelle-title d-flex justify-content-start">
                <p class="echelle-paragraph echelle-paragraph-green">Veille</p>
              </div>
              <div class='d-flex justify-content-between'>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.veille?.replaceAll('\n', '<br />')"></div>
                </div>
                <div>
                  <div [contentEditable]="true" [innerHTML]="echelle.echelle_datas.recommendations.actorVeille?.replaceAll('\n', '<br />')"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div *ngIf="!isDefaultUrlEchelle">
        <p>
          Vous ne pouvez pas éditer un pdf importé. Pour réactiver l'édition, veuillez réinitialiser le pdf.
        </p>
      </div>
    </div>

    <div>
      <iframe [src]='urlIframe'></iframe>
    </div>

</div>
