import {Component, OnInit} from '@angular/core';
import {EnvironmentConfiguration} from '../../../shared/EnvironmentConfiguration';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MapperService} from '../../../services/mapper.service';
import {PredictService} from '../../../services/predict.service';
import {UploadDialogComponent} from '../upload-dialog/upload.dialog.component';
import {A0, PlanAction} from '../../../shared/entities/A0.entity';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-echelle',
  templateUrl: './echelle.component.html',
  styleUrls: ['./echelle.component.scss']
})
export class EchelleComponent implements OnInit {

  private moduleAlias: string;
  public urlIframe: SafeResourceUrl;
  public idMapA0: string;
  public plan: A0 ;
  public showEchelle = true ;
  public showArchiveRecommendation: boolean = false;
  public showConsigne: boolean = false;
  public isDefaultUrlEchelle = false ;
  public listPicto: any;
  public urlPictos =  EnvironmentConfiguration.urls.picto;
  public echelle: any;

  constructor(
    private mapperService: MapperService,
    private predictService: PredictService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.idMapA0 = this.route.snapshot.paramMap.get('id');
    this.getPlan(this.idMapA0);
    this.getEchelle();
    this.getListPicto();
    this.isDefaultUrlEchelle = true;
  }

  addEvent(): void {
    this.echelle.echelle_datas.events.push({
      title :  '',
      subtitle: '',
      color: '',
      positionY: 0,
      fontSize: 15,
      fontSizeSubtitle: 10
    });
  }

  addParameters(): void {
    this.echelle.echelle_datas.parameters.push({
      image : '',
      description: '',
      color: '',
      asterisk: false,
      positionY: 0,
      fontSize: 15,
      fontSizeSubdescription: 10
    });
  }

  getEchelle(): void {
    this.mapperService.getEchelle(this.idMapA0).subscribe(data =>
      {
        this.echelle = data.echelle ;
        const url = EnvironmentConfiguration.urls.mapper + 'echelle/render/' + this.idMapA0 + '.pdf' ;
        this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;
      }
    );
  }

  deleteEvent(index): void{
    this.echelle.echelle_datas.events.splice(index, 1);
    this.updateEchelle();
  }

  deleteParameter(index): void{
    this.echelle.echelle_datas.parameters.splice(index, 1);
    this.updateEchelle();
  }

  updateEchelle(): void {
    console.log('objet json echelle', this.echelle);
    this.mapperService.updateEchelle(this.idMapA0, this.echelle).subscribe(data =>
    {
      console.log(data);
      const url = EnvironmentConfiguration.urls.mapper + 'echelle/render/' + this.idMapA0 + '.pdf' ;
      this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;
    });
  }

  public getPlan(id): void {
    this.mapperService.getPlan(id).subscribe(plan => {
      this.plan = plan;
      const url = this.plan.url_echelle;
      this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;

      this.isDefaultUrl();
    });
  }

  public switchToEchelle(): void{
    this.showEchelle = true;
    this.showArchiveRecommendation = false;
    this.showConsigne = false;
  }

  public switchToConsigne(): void{
    this.showConsigne = true;
    this.showEchelle = false;
    this.showArchiveRecommendation = false;
  }

  switchToArchiveRecommendation() {
    this.showArchiveRecommendation = true;
    this.showEchelle = false;
    this.showConsigne = false;
  }

  public openModal(): void {
    this.dialog.open(UploadDialogComponent, {
      data: {
        title: '',
        content: `
            <p class="pb-0 mb-0">
               Voulez vous modifier l'échelle de risque ?
               <br>
               Le document doit être un pdf de 500mm de haut pour 370mm de large.
            </p>
        `,
        plan: this.plan,
        planAction: PlanAction.ECHELLE
      },
      panelClass: 'dialog-warning',
      width: '600px'
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.mapperService.listPlan = [];
        this.getPlan(this.idMapA0);
      }
    });
  }

  public isDefaultUrl(): void {
    this.mapperService.getDefaultUrlAction(this.idMapA0, PlanAction.ECHELLE).subscribe(data => {
      this.isDefaultUrlEchelle = data.url === this.plan.url_echelle ;
    });
  }

  public getListPicto(): void {
    this.predictService.getListPictoInforisque().subscribe(list => {
      this.listPicto = list;
    });
  }


  addConsigne(type_consigne: any) {
    type_consigne.push({
      consigne:{
        text: '',
        fontSize: 10,
        color: '#000000',
        pos_y: 0
      },
      actor: {
        text: '',
        fontSize: 10,
        color: '#000000',

      }
    })
  }
  deleteLigneConsigne(type_consigne, index) {
    console.log(type_consigne, index);
    type_consigne.splice(index, 1);
    this.updateEchelle();
  }

  resetConsigneBlock(type_consigne: string) {
     switch (type_consigne) {
       case "PostCrise":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.postCrise.consigne.deltaY = 3;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.postCrise.consigne.interligne = 10;
         this.echelle.echelle_datas.recommendations_new.postCrise.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.updateEchelle();
       break ;
       case "securisationMaximale":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.securisationMaximale.consigne.deltaY = 1;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.securisationMaximale.consigne.interligne = 10;
         this.echelle.echelle_datas.recommendations_new.securisationMaximale.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.echelle.echelle_datas.recommendations_new.securisationMaximale[1].consigne.pos_y=2
         this.updateEchelle();
       break ;
       case "securisation":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.securisation.consigne.deltaY = 5;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.securisation.consigne.interligne = 12;
         this.echelle.echelle_datas.recommendations_new.securisation.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.updateEchelle();
         break ;
       case "mobilisation":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.mobilisation.consigne.deltaY = 4;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.mobilisation.consigne.interligne = 11;
         this.echelle.echelle_datas.recommendations_new.mobilisation.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.updateEchelle();
         break ;
       case "vigilance":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.vigilance.consigne.deltaY = 5;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.vigilance.consigne.interligne = 11;
         this.echelle.echelle_datas.recommendations_new.vigilance.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.echelle.echelle_datas.recommendations_new.vigilance[2].consigne.pos_y=2;
         this.updateEchelle();
         break ;
       case "veille":
         this.echelle.echelle_datas.recommendations_new.positionsBlock.veille.consigne.deltaY = 5;
         this.echelle.echelle_datas.recommendations_new.positionsBlock.veille.consigne.interligne = 10;
         this.echelle.echelle_datas.recommendations_new.veille.map(item => {
           item.consigne.fontSize = 10;
           item.consigne.color='#000000';
           item.consigne.pos_y=0;
         })
         this.updateEchelle();
         break ;
     }
  }
}
