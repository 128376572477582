import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '../services/loader.service';
import {Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-browser-detect',
  template: `
    <div *ngIf="!isChrome" class="wrong-navigator">
      <h2 class="d-flex justify-content-between">
    <span>
      <i class="bi bi-exclamation-triangle-fill me-2"></i>
      Attention
    </span>
        <button (click)="isChrome=true" class="pm-btn-nostyle">
          <i class="bi bi-x"></i>
        </button>
      </h2>
      <div class="d-flex justify-content-between align-items-start">
        <p>
          Ce module est optimisé pour Chrome, certaines fonctionnalités peuvent ne pas fonctionner
        </p>
        <a target="_blank" href="https://www.google.com/chrome/" class="pm-btn">Télécharger&nbsp;Chrome</a>
      </div>
    </div>
  `,
})
export class BrowserDetectionComponent {
  isChrome;

  constructor() {
    this.isChrome = navigator.userAgent.includes('Chrome');
  }
}

