import {Component, ElementRef, Inject, Renderer2, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {A0} from '../../shared/entities/A0.entity';
import {AuthService} from '../../services/auth.service';


@Component({
  selector: 'app-create-plan.dialog',
  templateUrl: './create-plan.dialog.component.html',
})
export class CreatePlanDialogComponent {

  plan: A0 = {};
  user;

  constructor(
    public render: Renderer2,
    public el: ElementRef,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
    },
  ) {
    this.authService.getSession().subscribe(user => {
      this.user = user;
      if (this.user.zones.length === 1) {
        this.plan.code_zone = this.user.zones[0].code_zone;
        this.plan.type_zone = this.user.zones[0].type_zone;
      }
    });
  }

  setZone(zone): void {
    this.plan.code_zone = zone.code_zone;
    this.plan.type_zone = zone.type_zone;
  }
}
