import {Base64} from 'js-base64';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EnvironmentConfiguration} from './EnvironmentConfiguration';
import {LoaderService} from '../services/loader.service';

export class ModuleInterface {

  moduleAlias: string;
  headers: HttpHeaders;
  withCredentials = false;

  constructor(
    alias: string,
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    withCredentials: boolean = false
  ) {
    this.moduleAlias = alias;
    this.withCredentials = withCredentials;
  }

  public getBaseUrl(): string {
    return EnvironmentConfiguration.urls[this.moduleAlias];
  }

  public addAuthorizationInAllRequest(): void {
    this.headers = new HttpHeaders({Authorization: ``});
  }

  public get(path: string, header?: HttpHeaders, hideLoader?: boolean): Observable<any> {
    if (!hideLoader) {
          this.loaderService.startLoading(path);
    }
    return this.httpClient.get(this.getBaseUrl() + path, {headers: header ? header : this.headers, observe: 'response', withCredentials: this.withCredentials})
               .pipe(
                 map(res => this.extractData(res, path)),
                 catchError(res => this.handleError(res, path))
               );
  }

  public basicAuth(path, login, password): Observable<any> {
    const valueAuth = `Basic ${Base64.encode(`${login}:${password}`)}`;
    const myHeaders = new HttpHeaders({Authorization: valueAuth});

    return this.httpClient.get(this.getBaseUrl() + path, {headers: myHeaders, observe: 'response', withCredentials: this.withCredentials})
      .pipe(
        map(this.extractData.bind(this)),
        catchError(this.handleError.bind(this))
      );
  }

  public put(path: string, obj: any, header?: HttpHeaders, hideLoader?: boolean): Observable<any> {
    if (!hideLoader) {
          this.loaderService.startLoading(path);
    }
    const json = obj != null ? JSON.stringify(obj) : '';
    return this.httpClient.put(this.getBaseUrl() + path, json, {headers: header ? header : this.headers, observe: 'response', withCredentials: this.withCredentials})
               .pipe(
                 map(res => this.extractData(res, path)),
                 catchError(res => this.handleError(res, path))
               );
  }

  public post(path: string, obj: any, header?: HttpHeaders, hideLoader?: boolean): Observable<any> {
    if (!hideLoader) {
          this.loaderService.startLoading(path);
    }
    const json = obj != null ? JSON.stringify(obj) : '';
    return this.httpClient.post(this.getBaseUrl() + path, json, {headers: header ? header : this.headers, observe: 'response', withCredentials: this.withCredentials})
               .pipe(
                 map(res => this.extractData(res, path)),
                 catchError(res => this.handleError(res, path))
               );
  }

  public delete(path: string, header?: HttpHeaders, hideLoader?: boolean): Observable<any> {
    if (!hideLoader) {
          this.loaderService.startLoading(path);
    }
    return this.httpClient.delete(this.getBaseUrl() + path, {headers: header ? header : this.headers, observe: 'response', withCredentials: this.withCredentials})
               .pipe(
                 map(res => this.extractData(res, path)),
                 catchError(res => this.handleError(res, path))
               );
  }

  public extractData(res: HttpResponse<any>, path: string): any {
    this.loaderService.stopLoading(path);

    if (res.status < 200 || res.status >= 300) {
      throw new Error('Bad response status: ' + res.status);
    }

    return res.body;
  }

  public handleError(error: HttpErrorResponse, path: string): any {
    this.loaderService.stopLoading(path);

    throw({...error});
  }
}
