import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let editor = {};

if (environment.production) {
  enableProdMode();
}

Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');
Cesium.Ion.defaultAccessToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2UzNzRkMS0yYWM3LTQ1MjYtOTZhOS1mNGQwYTgwMjMxNTAiLCJpZCI6MTkxMDQsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzQ5NTgxMDR9.d33Tmw6AIutGWBc6rtZFtWS0Jf7GGBDdhhysx7gvG60";

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
