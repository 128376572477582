<h2 mat-dialog-title>Nouvelle carte A0</h2>
<mat-dialog-content>
  <form #form="ngForm">
    <div class="row">
      <app-user-autocomplete [required]="true" (zone)="setZone($event)" *ngIf="this.user.zones.length !== 1"></app-user-autocomplete>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Titre du plan</mat-label>
        <input required matInput [(ngModel)]="plan.title" name="title">
      </mat-form-field>
    </div>
  </form>

  <hr>
</mat-dialog-content>
<div mat-dialog-actions class="justify-content-center align-items-center">
  <button class="pm-btn pm-btn-primary me-3" [disabled]="form.invalid || !plan.code_zone" [mat-dialog-close]="plan">Ajouter</button>
  <button class="pm-btn pm-btn-secondary" mat-dialog-close cdkFocusInitial>Annuler</button>
</div>
