import {Injectable} from '@angular/core';

@Injectable()
export class LocalParamsService {
    setParamsHistory(name: string, filters: {}) {
        localStorage.setItem(name, JSON.stringify(filters));
    }

    getParamsHistory(name: string) {
        return JSON.parse(localStorage.getItem(name));
    }

    cleanHistory(name: string) {
        localStorage.removeItem(name);
    }
}
