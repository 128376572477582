<div class="h-100 d-flex flex-column">
  <header class="main-header d-flex justify-content-between align-items-center">
    <div>
      <a routerLink="/plan/{{idMapA0}}" class="btn">
        <b><i class="bi bi-arrow-left-short"></i></b>
      </a>
    </div>

    <b>
      Stratégie d'alerte communale de {{plan?.title}}
    </b>

    <div class="d-flex justify-content-end me-2">
      <button class="pm-btn pm-btn-primary me-2" (click)="openModal()">Modifier stratégie d'alerte communale</button>

      <app-steps [type]="'strategiecom'"></app-steps>
    </div>
  </header>

  <div class="d-flex flex-grow-1 overflow-hidden">
    <div class="w-50 pt-3 overflow-y-auto">
      <div class="container-fluid">
        <div class="row g-3" *ngIf="isDefaultUrlStratCom">
          <div class="col-lg-6 col-md-12">
            <div class=" border border-2 rounded-2  border-opacity-75 p-3 bg-white">

              <h3 class="fw-bold border-bottom  border-opacity-75 pb-2 mb-2 d-flex align-items-center">
                <span class="cercle cercle-jaune me-2"></span>
                <span>Vigilance</span>
              </h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12" *ngFor="let vigilance of strategie.strategie_datas.vigilance">
                  <mat-checkbox [(ngModel)]="vigilance.data"
                                (change)="updateStrategie()">
                    {{vigilance.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class=" border border-2 rounded-2  border-opacity-75 p-3 bg-white">

              <h3 class="fw-bold border-bottom  border-opacity-75 pb-2 mb-2 d-flex align-items-center">
                <span class="cercle cercle-orange me-2"></span>
                <span>Mobilisation</span>
              </h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12" *ngFor="let mobilisation of strategie.strategie_datas.mobilisation">
                  <mat-checkbox [(ngModel)]="mobilisation.data"
                                (change)="updateStrategie()">
                    {{mobilisation.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class=" border border-2 rounded-2  border-opacity-75 p-3 bg-white">

              <h3 class="fw-bold border-bottom  border-opacity-75 pb-2 mb-2 d-flex align-items-center">
                <span class="cercle cercle-red me-2"></span>
                <span>Sécurisation</span>
              </h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12" *ngFor="let securisation of strategie.strategie_datas.securisation">
                  <mat-checkbox [(ngModel)]="securisation.data"
                                (change)="updateStrategie()">
                    {{securisation.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class=" border border-2 rounded-2  border-opacity-75 p-3 bg-white">

              <h3 class="fw-bold border-bottom  border-opacity-75 pb-2 mb-2 d-flex align-items-center">
                <span class="cercle cercle-purple me-2"></span>
                <span>Sécurisation Maximale</span>
              </h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12" *ngFor="let securisation_max of strategie.strategie_datas.securisation_max">
                  <mat-checkbox [(ngModel)]="securisation_max.data"
                                (change)="updateStrategie()">
                    {{securisation_max.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class=" border border-2 rounded-2  border-opacity-75 p-3 bg-white">

              <h3 class="fw-bold border-bottom  border-opacity-75 pb-2 mb-2 d-flex align-items-center">
                <span class="cercle cercle-grey me-2"></span>
                <span>Post-Crise</span>
              </h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12" *ngFor="let post_crise of strategie.strategie_datas.post_crise">
                  <mat-checkbox [(ngModel)]="post_crise.data"
                                (change)="updateStrategie()">
                    {{post_crise.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4" *ngIf="!isDefaultUrlStratCom">
          <p>
            Vous ne pouvez pas éditer un pdf importé. Pour réactiver l'édition, veuillez réinitialiser le pdf.
          </p>
        </div>
      </div>
    </div>



    <div class="w-50 h-100">
      <iframe class="w-100 h-100" [src]='urlIframe'></iframe>
    </div>
  </div>
</div>

