import {Pipe, PipeTransform} from '@angular/core';
import {capitalize} from '../tools';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
          return value;
        }

        return capitalize(value);
    }
}
