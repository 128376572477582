import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {capitalize} from './tools';
import {MapService} from '../services/map.service';

@Component({
  template: `
        <ng-template let-section ngFor [ngForOf]="getFilter()">
            <div  class="map-layer-filter-header d-flex justify-content-between align-items-center filter-type" [class.active]="filters[section].collapsed">
                <span class="mb-0 cursor-pointer fs-6 fw-bold map-layer-filter-title" (click)="collapse(filters[section])">
                    {{section}}
                    <i class="ml-3 bi bi-caret-{{filters[section].collapsed ? 'up' : 'down'}}"></i>
                </span>

                <mat-slide-toggle #slider [matTooltip]="filters[section].active ? 'Désactiver tous les filtres' : 'Activer tous les filtres'"
                                  (change)="toggleSection(section, $event)"
                                  [ngModel]="allFiltersActive(section)"
                                  class="mb-2">
                </mat-slide-toggle>
            </div>
            <ul *ngIf="filters[section].collapsed" class="map-layer-filter-list list-unstyled filter-type_list d-flex flex-column mb-0 mt-3 mb-3">
                <ng-template let-layer ngFor [ngForOf]="filters[section] | orderBy: ['title']">
                    <li class="d-flex align-items-center gap-2 pb-2" [style.order]="layer.sort" >
                        <mat-checkbox  (change)="applyFilters()"
                                     [name]="layer.section"
                                     [id]="layer.section + '-' + layer.title + '-filter'"
                                     [(ngModel)]="layer.active">
                          <span class="line-break p-0">
                              {{layer.title}}
                          </span>
                        </mat-checkbox>
                    </li>
                </ng-template>
            </ul>
        </ng-template>
    `,
  selector: 'map-layer-filter',
  styles: [`
        .line-break {
            white-space: normal;
            line-height: 16px;
        }
    `]
})

export class MapLayerFilterComponent implements OnChanges, OnInit {

  @Input() idMap = null;
  @Input() persist = true;
  @Input() reload: EventEmitter<any>;
  @Input() mapping: Map<string, any>;

  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  filters = {};
  map;

  constructor() {}

  ngOnChanges(): void {
    this.handleMapping();
  }

  ngOnInit(): void {
      this.handleMapping();

      this.reload.subscribe(() => {
          this.handleMapping();
      });
  }

  collapse(section) {
    section.collapsed = !section.collapsed;
  }

  getFilter() {
    return Object.keys(this.filters);
  }

  allFiltersActive(section) {
    return this.filters[section].every((filter) => filter.active);
  }

  toggleSection(section, $event) {
    this.filters[section].forEach((filter) => {
      filter.active = $event.checked;
    });
    this.applyFilters();
  }

  handleMapping() {
    if (!this.persist) {
      this.filters = {};
    }

    this.mapping.forEach((entity) => {
      Object.keys(entity.filter).forEach((type) => {
        this.setFilter(type, entity.filter[type])
      });
    });

    this.applyFilters();
  }

  setFilter(section, filter) {
    const _title = capitalize(filter.name?.replace(/_/g, ' '));

    if (this.filters[section]?.find((_filter) => _filter.id === filter.id)) {
      return;
    }

    if (!this.filters[section]) {
      this.filters[section] = [];
    }

    this.filters[section].push({
      section: section,
      title: _title,
      collapsed: true,
      name: filter.name,
      active: filter.active,
      sort: filter.sort,
      id: filter.id
    });
  }

  applyFilters() {
    const activeFilter: any = Object.entries(this.filters).map(([key, value]) => {
      // @ts-ignore
      return value.filter((filter) => {
        return filter.active;
      });
    }).flat().map((filter) => {
      return filter.id;
    });

    this.mapping.forEach((entity) => {
      const shouldBeActive = entity.filterBy.every((filterId) => activeFilter.includes(filterId));

      entity.eachLayers((layer) => layer.show = shouldBeActive);
      entity.show = shouldBeActive;
    });

    this.filterChange.emit(this.filters);
  }
}
