<div class="d-flex justify-content-center align-items-center page-login">
  <div class="page-login-block">
    <div class="text-center p-3 pt-0">
      <img src="/assets/images/favicon/android-chrome-192x192.png" [style.height.px]="30" alt="Predict Mapper Logo">
      <span class="fw-bold ms-2">MAPPER</span>
    </div>

    <div class="p-4 alert alert-warning">
      Cette page est reservée aux administrateurs. Veuillez accéder au predict mapper en passant par le wiki.
    </div>

    <form #form="ngForm" class="">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Identifiant</mat-label>
        <input matInput [(ngModel)]="auth.login" required name="login">
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password" [(ngModel)]="auth.password" required name="password">
      </mat-form-field>

      <div *ngIf="!!error" class="mb-3">
        <i><small class="text-danger">{{error}}</small></i>
      </div>

      <div class="d-flex w-100 justify-content-center">
        <button *ngIf="!isLoading" class="pm-btn pm-btn-secondary" [disabled]="form.invalid" (click)="login()">
          Connexion
        </button>

        <mat-spinner *ngIf="isLoading" [diameter]="34"></mat-spinner>
      </div>
    </form>



  </div>
</div>
