import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MapperService} from '../../../services/mapper.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadDialogComponent} from "../upload-dialog/upload.dialog.component";
import {PlanAction} from "../../../shared/entities/A0.entity";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-legende',
  templateUrl: './legende.component.html',
  styleUrls: ['./legende.component.scss']
})
export class LegendeComponent implements OnInit {

  public urlIframe: SafeResourceUrl;
  public idMapA0: string;
  public plan: any;

  constructor(
    private mapperService: MapperService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.idMapA0 = this.route.snapshot.paramMap.get('id');
    this.getPlan(this.idMapA0);
  }

  public openModal(): void {
    this.dialog.open(UploadDialogComponent, {
      data: {
        title: '',
        content: `
            <p class="pb-0 mb-0">
               Voulez vous modifier la légende ?
               <br>
               Le document doit être un pdf de 120mm de haut pour 300mm de large.
            </p>
        `,
        plan: this.plan,
        planAction: PlanAction.LEGEND_STATIC
      },
      panelClass: 'dialog-warning',
      width: '600px'
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.getPlan(this.idMapA0);
      }
    });
  }


  public getPlan(id): void {
    this.mapperService.getPlan(id).subscribe(plan => {
      this.plan = plan;
      const url = this.plan.url_legend_static;
      this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;
    });
  }
}
