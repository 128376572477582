import {Injectable} from '@angular/core';
import {ModuleInterface} from '../shared/ModuleInterface';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class PredictService  {

  public moduleCarto: ModuleInterface;
  public moduleGeoloc: ModuleInterface;
  public modulePicto: ModuleInterface;

  zoneList;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.moduleCarto = new ModuleInterface('carto', this.httpClient, this.loaderService);
    this.moduleGeoloc = new ModuleInterface('geoloc', this.httpClient, this.loaderService);
    this.modulePicto = new ModuleInterface('picto', this.httpClient, this.loaderService);
  }

  getPolygon(code_zone, type_zone): Observable<any> {
    return this.moduleGeoloc.post(`zone/polygon_from_list`, {list: [{
        type_zone,
        code_zone,
      }]
    });
  }

  getZmi(bbox): Observable<any> {
    return this.moduleCarto.get(`layer/zmi/${bbox}`);
  }

  getZms(bbox): Observable<any> {
    return this.moduleCarto.get(`layer/zms/${bbox}`);
  }


  getZoneWithFilters(term, filters): Observable<any> {
    return this.moduleGeoloc.post('zones/search', {search : term, filters})
      .pipe(map((v) => v.list));
  }

  getListPictoInforisque(): Observable<any> {
    return this.modulePicto.get('picto/inforisque/list')
      .pipe(map((v) => v.list));
  }

  getListPictoPlan(): Observable<any> {
    return this.modulePicto.get('picto/plan/list')
      .pipe(map((v) => v.list));
  }
}
