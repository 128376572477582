import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '../services/loader.service';
import {Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading = new Subject();

  constructor(
    public loaderService: LoaderService,
    public cdr: ChangeDetectorRef,
  ) {
    this.isLoading.pipe(
      startWith(false)
    );
  }

  ngOnInit(): void {
    this.loaderService.listener.subscribe(listLoader => {
      this.isLoading.next(listLoader.length);
      this.cdr.detectChanges();
    });
  }
}

