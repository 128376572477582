import {Injectable} from '@angular/core';
import {ModuleInterface} from '../shared/ModuleInterface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {Observable} from 'rxjs';

import {
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http';
import {scan} from 'rxjs/operators';
import {PlanAction, SnapshotType} from '../shared/entities/A0.entity';
import {AuthService} from './auth.service';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

export interface DownloadEvent {
  progress: number;
  content: Blob | null;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

export interface UploadEvent {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  content: any;
}

@Injectable()
export class DownloadService  {

  public module: ModuleInterface;
  public headers: HttpHeaders;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.module = new ModuleInterface('mapper', this.httpClient, this.loaderService, true);
  }

  download(url: string): Observable<DownloadEvent> {
    return this.httpClient.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    }).pipe(download());
  }

  uploadSvg(file, idPlan): Observable<UploadEvent> {
    const headers = new HttpHeaders({
      'Content-type': 'text/xml',
    });

    const url = this.module.getBaseUrl();

    return this.httpClient.post(url + `upload/labels-svg/${idPlan}`, file, {
      headers,
      reportProgress: true,
      observe: 'events',
      withCredentials: true
    }).pipe(upload());
  }

  uploadSnapshot(file, idPlan, type: SnapshotType): Observable<UploadEvent> {
    const data = new FormData();
    data.append('file', file);

    const url = this.module.getBaseUrl();

    return this.httpClient.post(url + `upload/snapshot/${idPlan}/${type}`, data, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events',
      withCredentials: true
    }).pipe(upload());
  }

  uploadPlanAction(file, idPlan, action: PlanAction): Observable<UploadEvent> {
    const data = new FormData();
    data.append('file', file);

    const url = this.module.getBaseUrl();

    return this.httpClient.post(url + `upload/action-${action}/${idPlan}`, data, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events',
      withCredentials: true
    }).pipe(upload());
  }

  uploadCartoucheLogo(file, idPlan): Observable<UploadEvent> {
    const data = new FormData();
    data.append('file', file);

    const url = this.module.getBaseUrl();

    return this.httpClient.post(url + `upload/logo-entete/${idPlan}`, data, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events',
      withCredentials: true
    }).pipe(upload());
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64 to raw binary data held in a string
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
  }

}

export function upload(): (source: Observable<HttpEvent<any>>) => Observable<UploadEvent> {
  return (source: Observable<HttpEvent<any>>) =>
    source.pipe(
      scan((previous: UploadEvent, event: HttpEvent<any>): UploadEvent => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null
            };
          }
          if (isHttpResponse(event)) {
            return {
              progress: 100,
              state: 'DONE',
              content: event.body
            };
          }
          return previous;
        },
        {state: 'PENDING', progress: 0, content: null}
      )
    );
}

export function download(): (source: Observable<HttpEvent<Blob>>) => Observable<DownloadEvent> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan((previous: DownloadEvent, event: HttpEvent<Blob>): DownloadEvent => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null
            };
          }
          if (isHttpResponse(event)) {
            return {
              progress: 100,
              state: 'DONE',
              content: event.body
            };
          }
          return previous;
        },
        {state: 'PENDING', progress: 0, content: null}
      )
    );
}
