import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

  listener = new EventEmitter(true);
  listLoading: string[] = [];

  constructor() { }

  startLoading(alias: string): void {
    if (this.listLoading.includes(alias)) {
      return;
    }
    this.listLoading.push(alias);
    this.listener.emit(this.listLoading);
  }

  stopLoading(alias: string): void {
    if (!this.listLoading.includes(alias)) {
      return;
    }
    this.listLoading = this.listLoading.filter((a) => a !== alias);
    this.listener.emit(this.listLoading);
  }
}
