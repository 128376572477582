<header class="main-header d-flex justify-content-between align-items-center flex-wrap">
  <a  routerLink="/plan/{{idMapA0}}" class="btn">
    <b><i class="bi bi-arrow-left-short"></i></b>
  </a>

  <b class="flex-fill text-center">
    CCM de {{plan?.title}}
  </b>

  <div class="d-flex justify-content-end me-2">
    <button class="pm-btn pm-btn-primary me-2" (click)="openModal()">Modifier CCM</button>

    <app-steps [type]="'ccm'"></app-steps>
  </div>
</header>
<div class="iframe">
  <iframe [src]='urlIframe'></iframe>
</div>
