import {Component, Input, OnInit} from '@angular/core';
import {MapperService} from '../../services/mapper.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  template: `
    <div class="border border-1 border-opacity-25 rounded-2 d-flex overflow-hidden fw-semibold" [style.height.px]="40">
      <div role="button" [style.background]="isStepDone ? '#06beb8':'white'"
           (click)="setStep(true)" class="p-2 cursor  text-nowrap {{isStepDone ? 'text-white' : ''}}">
        <i class="bi bi-check"></i> Validé
      </div>

      <div role="button" [ngClass]="{
        'bg-danger': isStepDone === false
        }" (click)="setStep(false)" class="p-2 cursor text-nowrap">
        <i class="bi bi-x"></i> Non validé
      </div>
    </div>
  `,
  selector: 'app-steps',
})
export class StepsComponent implements OnInit {

  public isStepDone = null;
  public idPlan = null;

  @Input() type;

  constructor(
    private mapperService: MapperService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.idPlan = this.route.snapshot.paramMap.get('id');
    this.isStepsDone(this.idPlan);
  }

  public setStep(done): void {
    this.isStepDone = done;

    const request = done
      ? this.mapperService.setStepDone(this.idPlan, this.type)
      : this.mapperService.setStepUndone(this.idPlan, this.type);

    request.subscribe(() => {
      this.snackbar.open(done ? 'Étape validée' : 'Étape invalidée', 'ok', {
        duration: 3000
      });
    }, (error) => {
      this.snackbar.open('Une erreur est survenue', 'ok', {
        duration: 3000
      });
    });
  }

  public isStepsDone(id): void {
    this.mapperService.getStepsDone(id).subscribe(data => {
      this.isStepDone = data.steps_dones.indexOf(this.type) > -1;
    });
  }
}
