<h2 mat-dialog-title>Titre du plan</h2>
<mat-dialog-content>
  <form #form="ngForm">
    <div class="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Titre</mat-label>
        <input matInput [(ngModel)]="title" required name="title">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="justify-content-center align-items-center">
  <button class="pm-btn pm-btn-primary me-3" (click)="save()" [disabled]="form.invalid || isLoading">Sauvegarder</button>
  <button class="pm-btn" mat-dialog-close [disabled]="isLoading" cdkFocusInitial>Annuler</button>
</div>
