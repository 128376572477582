import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ModuleInterface} from '../shared/ModuleInterface';
import {LoaderService} from './loader.service';
import {Observable} from 'rxjs';
import {User} from "../shared/entities/user.entity";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected module: ModuleInterface;

  public redirectUrl = 'dashboard';

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public router: Router,
  ) {
    this.module = new ModuleInterface('mapper', this.httpClient, this.loaderService, true);
  }

  public logout(): Observable<any> {
    return this.module.get('logout');
  }

  public tryLogin(login: string, password: string): Observable<any> {
    return this.module.basicAuth('login', login, password);
  }

  public getSession(): Observable<any> {
    return this.module.get('session').pipe(
      map((a) => {
        sessionStorage.setItem('user', JSON.stringify(a));
        return a;
      }),
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return null;
      })
    );
  }

  public jwtLogin(token: string): Observable<any> {
    return this.module.get(`login-jwt?token=${token}`);
  }
}
