<app-browser-detect></app-browser-detect>

<header class="main-header d-flex justify-content-between align-items-center">
  <div style="width:20%">
    <a href="/plan/{{plan?.id}}/5" routerLink="/plan/{{plan?.id}}/5" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b>
    <span class="color-grey">Gestion des cartes</span> : {{_type | replace: '_' : ' '}}
  </b>

  <div style="width:20%" class="d-flex justify-content-end">

  </div>
</header>

<div class="page-map">
  <div class="page-map-panel bg-white">
    <div class="pm-panel d-flex justify-content-between flex-column h-100">
      <div class="pm-panel-content">
        <div class="m-3">

          <app-collapse *ngIf="currentSnapshot.title" name="Titre">
            <mat-form-field appearance="outline" class="w-100 mt-3">
              <mat-label>Titre du zoom</mat-label>
              <input matInput [(ngModel)]="currentSnapshot.title" [ngModelOptions]="{standalone: true}" [maxLength]="50" required name="title">
            </mat-form-field>
          </app-collapse>

          <app-collapse name="Options">
            <p>
              Certains calques sont trop lourds pour être chargés en temps réel ( Batiment, Routes, Surfaces en eau, Cours d'eau, Toponymes )
            </p>
            <p>
              Ajuster la vue désirée sur la carte puis charger les calques spécifiques
            </p>
            <div class="text-center">
              <div>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Calques</mat-label>
                  <mat-select [(ngModel)]="layersToLoad" multiple>
                    <mat-option *ngFor="let layer of allLayersToLoad" [value]="layer.alias">{{layer.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <button class="pm-btn me-2" [disabled]="isLoadingLayer" (click)="loadAllLayer()">
                <i class="bi bi-stack"></i> Charger les calques
              </button>
            </div>


          </app-collapse>

          <app-collapse name="Calques">
            <div *ngFor="let item of manageableLayers; let i = index" class="pm-layer  mb-2 ">
              <div class="d-flex justify-content-between align-items-center">
                <b>{{item.name}}</b>
                <div>
                  <mat-slide-toggle (change)="handleVisibility(item)"
                                    [disabled]="!item.layer.entities?.values?.length"
                                    [checked]="!!item.layer.entities?.values?.length && item.layer.show">
                  </mat-slide-toggle>
                </div>
              </div>
              <div *ngIf="(item.name === 'ZMI' || item.name === 'ZMS') && item.layer.show && !!item.layer.entities?.values?.length" class="d-flex justify-content-between align-items-center">
                <b>Niveaux</b>
                <div>
                  <mat-slide-toggle (change)="handleNiveauxVisibility(item)"
                                    [name]="item.alias"
                                    [disabled]="!item.layer.entities?.values?.length"
                                    [checked]="item.layer.showNiveau && !!item.layer.entities?.values?.length">
                  </mat-slide-toggle>
                </div>
              </div>

              <div *ngIf="!!item.layer.toggleLabels && item.layer?.entities?.values.length" class="mt-1">
                  <div class="mb-1" >
                    <mat-checkbox (change)="item.layer.toggleLabels()" [(ngModel)]="item.layer.showLabels">Affiche les labels</mat-checkbox>
                  </div>

                  <mat-form-field *ngIf="item.layer.showLabels" appearance="outline" class="w-100 mt-3">
                    <mat-label>Distance entre les labels</mat-label>
                    <input matInput type="number" [value]="item.layer.distanceBetweenLabels" (change)="item.layer.updateDistanceBetweenWatercourseLabels($event.target?.value)" required name="label_distance">
                  </mat-form-field>
              </div>


              <div *ngIf="item.name === 'PCS'" class="mt-1">
                <mat-form-field appearance="outline" class="w-100 mt-3">
                  <mat-label>Taille des pictos</mat-label>
                  <input matInput type="number" [value]="item.layer.initialSize" (change)="updatePCSpictoSize($event.target, item)" required name="size">
                </mat-form-field>

                <div class="mt-2 cursor-pointer" (click)="item.collapsed = !item.collapsed">
                  Filtrage avancé <i class="bi bi-caret-{{item.collapsed ? 'up' : 'down' }}"></i>
                </div>
                <div class="mt-3 mb-1" *ngIf="item.collapsed">

                  <map-layer-filter [mapping]="mapComponent.planEntities" [reload]="mapComponent.reloadFilter">
                  </map-layer-filter>
                </div>
              </div>

              <i class="mt-1" *ngIf="!item.layer.autoLoad">
                <small>Ce calque doit être chargé manuellement</small>
              </i>
            </div>
          </app-collapse>
        </div>



      </div>
      <div class="pm-panel-actions text-center p-3">
        <button class="pm-btn pm-btn-primary me-3" [disabled]="isLoadingLayer" type="button" (click)="screenshot()">
          Generer le zoom
        </button>
      </div>
    </div>
  </div>
  <div class="page-map-content">
    <app-map-cesium #map *ngIf="!!plan"
                    [snapshot]="currentSnapshot"
                    [type]="_type"
                    [plan]="plan"
                    [canvasMode]="'3d'"
                    (isLoadingLayer)="isLoadingLayer = $event"
                    (whenMapIsInit)="mapIsInit()">
      <div *ngIf="currentSnapshot.title" class="map-title"
           id="title_position"
           [hidden]="!currentSnapshot.title"
           cdkDragBoundary=".dragBoundary"
           (cdkDragEnded)="dragEnd($event)"
           cdkDrag [cdkDragFreeDragPosition]="titleInitialPosition">
        {{currentSnapshot.title}}
      </div>
    </app-map-cesium>
  </div>
</div>
