import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {A0, PlanAction} from '../../../shared/entities/A0.entity';
import {DownloadService} from '../../../services/download.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MapperService} from '../../../services/mapper.service';

@Component({
  templateUrl: 'upload.dialog.component.html'
})
export class UploadDialogComponent {

  public title = '';
  public content = '';
  public btnCancel = '';
  public btnResetPdf = '';
  public btnNewPdf = '';
  public btnActionClass = '';
  public btnCancelClass = '';
  public plan: A0;
  public planAction: PlanAction;
  public type: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      content: string,
      btnCancel?: string,
      btnAction?: string,
      btnActionClass?: string,
      btnCancelClass?: string,
      plan?: A0,
      planAction?: PlanAction,
    },
    private mapperService: MapperService,
    public downloadService: DownloadService,
    public snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadDialogComponent>,


  ) {
    this.title = data.title;
    this.content = data.content;

    this.btnCancel = data.btnCancel ? data.btnCancel : 'Annuler';
    this.btnCancelClass = data.btnCancelClass ? data.btnCancelClass : 'btn btn-primary';

    this.btnResetPdf = data.btnAction ? data.btnAction : 'Réinitialiser le pdf';
    this.btnNewPdf = data.btnAction ? data.btnAction : 'Envoyer un pdf';
    this.btnActionClass = data.btnActionClass ? data.btnActionClass : 'btn btn-outline-secondary';

    this.plan = data.plan;
    this.planAction = data.planAction;
    this.type = 'url_' + this.planAction;
  }


  public onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.downloadService.uploadPlanAction(file, this.plan.id, this.planAction).subscribe(res => {
      if (res?.state === 'DONE') {

        this.snackbar.open('Pdf sauvegardée', 'ok', {
          duration: 3000
        });

        this.dialogRef.close(res);

      }
    }, (err) => {
      this.snackbar.open('Une erreur est survenue pendant l\'upload', 'ok', {
        duration: 3000
      });
      console.error('[UploadDialogComponent] -> uploadPlanAction() -> ', err);
    });
  }

  public resetDefaultUrl(): void {

    this.mapperService.resetDefaultUrlAction(this.plan.id, this.planAction).subscribe((res) => {
      this.snackbar.open('le pdf par défaut a bien été restauré', 'ok', {
        duration: 3000
      });

      this.dialogRef.close(res);


    }, (err) => {
      this.snackbar.open('Une erreur est survenue', 'ok', {
        duration: 3000
      });
      console.error('[UploadDialogComponent] -> resetDefaultUrl() -> ', err);
    });

  }


}

