import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../services/loader.service';
import {Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-collapse',
  template: `
    <div class="pm-collapse mb-3">
      <div class="pm-collapse-header" (click)="open = !open">
        <span class="pm-collapse-title">{{name}}</span>
        <i class="bi bi-chevron-{{open ? 'down' : 'up'}}"></i>
      </div>
      <div class="pm-collapse-content mt-2 pt-3" [class.pm-collapse-content-opened]="open">
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class CollapseComponent {
  @Input() name = '';
  @Input() open = true;
}

